import useOptions from 'components/Options/OptionsContext/useOptions';
import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useCourseApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();
  const { refetchOptions } = useOptions();

  const getCourses = async (filters) => {
    try {
      const { data } = await api({
        url: `/courses${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getCourse = async (uid) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createCourse = async (course) => {
    try {
      const { data } = await api({
        url: '/courses',
        method: 'post',
        data: course
      });
      refetchOptions();
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateCourse = async (uid, course) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}`,
        method: 'put',
        data: course
      });
      refetchOptions();
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateCourseContinuityDiscount = async (uid, discounts) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}/continuity-discount`,
        method: 'put',
        data: discounts
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteCourseContinuityDiscount = async (uid) => {
    try {
      const { data } = await api({
        url: `/courses/${uid}/continuity-discount`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getCourses,
    getCourse,
    createCourse,
    updateCourse,
    updateCourseContinuityDiscount,
    deleteCourseContinuityDiscount
  };
};

export default useCourseApi;
