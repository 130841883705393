import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import { Box, Tabs, Tab, Grid, Stack } from '@mui/material';
import useStudentApi from '../../components/Student/useStudentApi';
import { Title } from 'ui';
import StudentCoursesList from 'components/Student/StudentCoursesList/StudentCoursesList';
import StudentSubscriptions from 'components/Student/StudentSubscriptions';
import UserDetails from 'components/User/UserDetails/UserDetails';
import StudentInvoiceDetails from 'components/Student/StudentInvoiceDetails';
import NewSubscriptionButton from 'components/Subscription/NewSubscriptionButton';
import StudentNotes from '../../components/Notes/StudentNotes';
import NewNoteButton from 'components/Notes/NewNoteButton';

const StudentProfilePage = () => {
  const [student, setStudent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const { getStudentProfile } = useStudentApi();
  const { id } = useParams();
  const { t } = useTranslation();
  const [notesRefetchTrigger, setNotesRefetchTrigger] = useState(0);
  const [subscriptionsRefetchTrigger, setSubscriptionsRefetchTrigger] = useState(0);

  const fetchStudent = async (id) => {
    const response = await getStudentProfile(id);
    setStudent(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchStudent(id);
  }, [id]);

  const handleTabChange = (e, v) => {
    setTab(v);
  };

  if (loading) {
    return <>Loading</>;
  }

  return (
    <>
      {!loading && (
        <>
          <Title title={`${student.studentProfile.lastName} ${student.studentProfile.firstName}`} />

          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Tabs value={tab} onChange={handleTabChange} aria-label='Course tabs'>
              <Tab label={t('students.details')} id='details' />
              <Tab label={t('courses.subscription-title')} id='subscriptions' />
              <Tab label={t('students.notes')} id='notes' />
            </Tabs>
            <Stack direction='row' gap={2}>
              {tab === 2 ? (
                <NewNoteButton studentId={id} setRefetchTrigger={setNotesRefetchTrigger} />
              ) : tab === 1 ? (
                <NewSubscriptionButton studentId={id} setRefetchTrigger={setSubscriptionsRefetchTrigger} />
              ) : null}
            </Stack>
          </Stack>

          {tab === 0 && (
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Stack gap={4}>
                    <UserDetails userId={id} isNaturalPerson={student.studentProfile.isCompany === '0'} />
                    <StudentCoursesList studentId={id} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StudentInvoiceDetails studentId={student.studentProfile.id} refetchStudent={fetchStudent} />
                </Grid>
              </Grid>
            </Box>
          )}

          {tab === 1 && (
            <Box sx={{ mt: 4 }}>
              <StudentSubscriptions 
                studentId={id} 
                refetchTrigger={subscriptionsRefetchTrigger}
                setRefetchTrigger={setSubscriptionsRefetchTrigger}
              />
            </Box>
          )}

          {tab === 2 && (
            <Box sx={{ mt: 4 }}>
              <StudentNotes
                studentId={id}
                refetchTrigger={notesRefetchTrigger}
                setRefetchTrigger={setNotesRefetchTrigger}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default StudentProfilePage;
