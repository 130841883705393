import AuthPageLayout from './AuthPageLayout';
import { Form, Link } from 'ui';
import signUpFormFields from './signUpFormFields';
import { useState } from 'react';
import useViewer from '../../components/Viewer/ViewerContext/useViewer';
import { Typography } from '@mui/material';

const SignUpPage = () => {
  const [loading, setLoading] = useState(false);
  const { signup } = useViewer();

  const handleSubmit = async (fields) => {
    setLoading(true);
    try {
      await signup(fields);
    } catch(e) {
      setLoading(false);
    } 
  };

  return (
    <AuthPageLayout
      title='Regisztráció'
      description='Kedves Tanuló! Mindössze az alábbi néhány adat megadásával máris regisztrálhatsz, és böngészhetsz tanfolyamaink között!'
    >
      <Form
        fields={signUpFormFields}
        onSubmit={handleSubmit}
        submitLabel='Regisztráció'
        fullWidthSubmit
        loading={loading}
      />

      <Typography variant='body2' sx={{ mt: 4, textAlign: 'center' }}>
        Ha már van Up2You fiókod, akkor <Link to='/login'>lépj be!</Link>
      </Typography>
    </AuthPageLayout>
  );
};

export default SignUpPage;
