import TablePage from 'components/Common/TablePage';
import useTransactionsFields from 'components/Transactions/useTransactionsFields';
import useTransactionApi from 'components/Transactions/useTransactionApi';
import useSubscriptionApi from 'components/Subscription/useSubscriptionApi';
import useSubscriptionFilters from 'components/Subscription/useSubscriptionFilters';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const StudentSubscriptions = ({ studentId, refetchTrigger, setRefetchTrigger }) => {
  const { getTransactions } = useTransactionApi({ studentId });
  const fields = useTransactionsFields({ hideStudent: true, setRefetchTrigger });
  const { updateSubscription } = useSubscriptionApi();
  const filtersConfig = useSubscriptionFilters(studentId);
  const { viewerRoleIs, viewerRoles } = useViewer();

  return (
    <TablePage
      fields={fields}
      updateIdField='subscriptionId'
      onGetRows={getTransactions}
      onUpdateRow={viewerRoleIs(viewerRoles.ADMIN) ? updateSubscription : null}
      filterConfig={filtersConfig}
      refetchTrigger={refetchTrigger}
    />
  );
};

export default StudentSubscriptions;
