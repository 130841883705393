import useNotesApi from './useNotesApi';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { IconButton } from '@mui/material';

export const ToggleNoteImportanceIcon = ({ noteId, important, lastModification, setRefetchTrigger }) => {
  const { toggleNoteImportance } = useNotesApi();
  const toggleImportance = async (e) => {
    e.stopPropagation();
    await toggleNoteImportance(noteId, lastModification);
    setRefetchTrigger((prev) => prev + 1);
  };
  return important ? (
    <IconButton onClick={toggleImportance}>
      <StarRoundedIcon color='primary'/>
    </IconButton>
  ) : (
    <IconButton onClick={toggleImportance}>
      <StarBorderRoundedIcon />
    </IconButton>
  );
};
