import { useEffect, useRef, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import useApi from 'api/useApi';
import { useError } from 'hooks/useError';
import { Card, Divider, Stack, Typography, Link as MaterialLink } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Spinner from 'ui/Spinner/Spinner';
import useOptions from 'components/Options/OptionsContext/useOptions';
import { Button } from 'ui';

const MAX_RETRIES = 4;

const PaymentConfirmationPage = () => {
  const [transaction, setTransaction] = useState();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get('paymentId');
  const { api } = useApi();
  const { enqueueErrorSnackbar } = useError();
  const interval = useRef();
  const [retries, setRetries] = useState(0);
  const loading = !transaction || (transaction.status === 'Prepared' && retries < MAX_RETRIES);
  const { getOptionValue } = useOptions();

  const getTransaction = async (id) => {
    try {
      const { data } = await api({
        url: `/subscription/process?paymentId=${id}`,
        method: 'post'
      });
      if (data.transaction.status !== 'Prepared' && interval.current) {
        clearInterval(interval.current);
      }
      setTransaction(data.transaction);
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  useEffect(() => {
    if (retries > MAX_RETRIES) {
      clearInterval(interval.current);
    }
  }, [retries]);

  useEffect(() => {
    interval.current = setInterval(() => {
      if (paymentId && !transaction) {
        setRetries((prev) => prev + 1);
        getTransaction(paymentId);
      }
    }, 2000);

    return () => clearInterval(interval.current);
  }, []);

  return (
    <Card sx={{ maxWidth: '28rem', margin: '0 auto', p: 4, textAlign: 'center' }}>
      {loading && (
        <>
          <Spinner size={50} sx={{ my: 1.4 }} />
          <Typography variant='h5' sx={{ mt: 1, textAlign: 'center' }}>
            Tranzakció feldolgozása
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            Egy kis türlemet kérünk a tranzakció feldolgozásához.
          </Typography>
        </>
      )}
      {!loading && transaction.status === 'Succeeded' && (
        <>
          <CheckCircleTwoToneIcon sx={{ fontSize: '4rem', color: 'success.main' }} />
          <Typography variant='h5' sx={{ mt: 1, textAlign: 'center' }}>
            Sikeres fizetés
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            A fizetés sikeresen megtörtént.
          </Typography>

          <Divider sx={{ my: 4 }} />
          <Typography variant='h4' sx={{ textAlign: 'center' }}>
            {parseInt(transaction.price).toLocaleString('hu')} Ft
          </Typography>
          <Divider sx={{ my: 4 }} />
          <Stack gap={1}>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam:
              </Typography>
              <Typography variant='body1'>{transaction.course_name}</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Órák:
              </Typography>
              <Typography variant='body1'>{parseInt(transaction.quantity)} óra</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Nyelv:
              </Typography>
              <Typography variant='body1'>{getOptionValue('languages', transaction.course_language)}</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam típusa:
              </Typography>
              <Typography variant='body1'>
                {getOptionValue('courseGroupTypes', transaction.course_group_type)}
              </Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam helyszíne:
              </Typography>
              <Typography variant='body1'>
                {getOptionValue('courseLocationTypes', transaction.course_location_type)}
              </Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Vásárlás dátuma:
              </Typography>
              <Typography variant='body1'>{transaction.created_on}</Typography>
            </Stack>
          </Stack>

          <Divider sx={{ my: 4 }} />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            component={Link}
            to={`/courses/${transaction.course_id}`}
          >
            Tovább a tanfolyamra
          </Button>
        </>
      )}

      {!loading && transaction.status !== 'Succeeded' && transaction.status !== 'Prepared' && (
        <>
          <ErrorTwoToneIcon sx={{ fontSize: '4rem', color: 'error.main' }} />
          <Typography variant='h5' sx={{ mt: 1, textAlign: 'center' }}>
            Sikertelen fizetés
          </Typography>
          <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
            A fizetés során valami probléma lépett fel, kérlek probáld újra vagy vedd fel velünk a kapcsolatot az alábbi
            email címen:
          </Typography>
          <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
            <MaterialLink href='mailto:recepcio@up2you.hu'>recepcio@up2you.hu</MaterialLink>
          </Typography>

          <Divider sx={{ my: 4 }} />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            component={Link}
            to={`/courses/${transaction.course_id}`}
          >
            Vissza a tanfolyamra
          </Button>
        </>
      )}

      {!loading && transaction.status === 'Prepared' && (
        <>
          <HelpTwoToneIcon sx={{ fontSize: '4rem', color: 'warning.main' }} />
          <Typography variant='h5' sx={{ mt: 1, textAlign: 'center' }}>
            Fizetés folyamatban
          </Typography>
          <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
            A fizetés során valami probléma lépett fel, vedd fel velünk a kapcsolatot az alábbi email címen:
          </Typography>
          <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
            <MaterialLink href='mailto:recepcio@up2you.hu'>recepcio@up2you.hu</MaterialLink>
          </Typography>

          <Divider sx={{ my: 4 }} />

          <Button
            variant='contained'
            color='primary'
            fullWidth
            component={Link}
            to={`/courses/${transaction.course_id}`}
          >
            Vissza a tanfolyamra
          </Button>
        </>
      )}
    </Card>
  );
};

export default PaymentConfirmationPage;
