import React from 'react';
import { DATE_FORMATS, formatDate } from 'utils/date';
import { Typography, Grid } from '@mui/material';

const PriceDisplay = ({ subscription }) => {
  const hasDiscount =
    subscription.discountedPrice < subscription.price && Object.entries(subscription.availableDiscounts).length > 0;

  return hasDiscount ? (
    <>
      <Grid container spacing={1}>
        <Grid item alignContent='center'>
          <Typography sx={{ mt: 3 }} variant='h6' style={{ textDecoration: 'line-through' }}>
            {parseInt(subscription.price).toLocaleString('hu')} Ft
          </Typography>
        </Grid>
        <Grid item alignContent='center'>
          <Typography sx={{ mt: 3 }} variant='h4' color='red'>
            {parseInt(subscription.discountedPrice).toLocaleString('hu')} Ft
          </Typography>
        </Grid>
      </Grid>
      {Object.entries(subscription.availableDiscounts).map((discount, index) => {
        return (
          <Typography
            sx={{ mb: index < Object.entries(subscription.availableDiscounts).length - 1 ? 0 : 3 }}
            variant='body2'
            key={discount[0]}
          >
            {displayDiscountEntry(discount)}
          </Typography>
        );
      })}
    </>
  ) : (
    <Typography sx={{ mt: 3, mb: 3 }} variant='h4'>
      {parseInt(subscription.price).toLocaleString('hu')} Ft
    </Typography>
  );
};

const displayDiscountEntry = (discount) => {
  const discountName = discount[0];
  const discountValue = typeof discount[1] === 'string' ? discount[1] : discount[1].discount;
  const discountValueDisplay = discountValue.endsWith('%') ? discountValue : `${discountValue} Ft`;
  const discountExpiration =
    discount[1].expiration != null
      ? formatDate(discount[1].expiration, DATE_FORMATS.shortDate).replace(/\.$/, '')
      : discount[1].hasSubscription
      ? 'A bérleted lejártáig'
      : null;

  return `- ${discountName}: ${discountValueDisplay}${discountExpiration != null ? ` (${discountExpiration}-ig)` : ''}`;
};

export default PriceDisplay;
