import { LinearProgress, Tooltip, Box } from '@mui/material';
import { Link } from 'ui';
import StatusChip from '../Common/StatusChip';
import { getHourAndMinutesFromMinutes, DATE_FORMATS, formatDate } from 'utils/date';
import useOptions from 'components/Options/OptionsContext/useOptions';
import * as yup from 'yup';

const useSubscriptionFields = (hideStudent) => {
  const { getOptionValue } = useOptions();
  return [
    {
      name: 'userName',
      showInTable: !hideStudent,
      cellRenderer: (row) => (
        <Link underline='hover' sx={{ fontWeight: 700 }} to={`/students/${row.userId}`}>
          {row.userName}
        </Link>
      ),
      fieldProps: {
        label: 'Tanuló'
      }
    },
    {
      name: 'courseName',
      showInTable: true,
      cellRenderer: (row) => (
        <Link underline='hover' sx={{ fontWeight: 700 }} to={`/courses/${row.courseId}`}>
          {row.courseName}
        </Link>
      ),
      fieldProps: {
        label: 'Tanfolyam'
      }
    },
    {
      name: 'transactionStatus',
      showInTable: true,
      cellRenderer: (row) => <>{getOptionValue('transactionStatuses', row.transactionStatus)}</>,
      fieldProps: {
        label: 'Tr. státusz'
      }
    },
    {
      name: 'start',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.start ? formatDate(row.start, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Kezdet'
      },
      rule: yup.string().test('is-before-end', 'A kezdő dátum nem lehet a végdátum után', function (value) {
        const { end } = this.parent;
        if (!end || !value) {
          return true;
        }
        return new Date(Number(end) * 1000) >= new Date(Number(value) * 1000);
      })
    },
    {
      name: 'end',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.end ? formatDate(row.end, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Lejárat'
      },
      rule: yup.string().test('is-after-start', 'A lejárati dátum nem lehet a kezdet előtt', function (value) {
        const { start } = this.parent;
        if (!start || !value) {
          return true;
        }
        return new Date(Number(value) * 1000) >= new Date(Number(start) * 1000);
      })
    },
    {
      name: 'hours',
      type: 'number',
      showInTable: true,
      cellRenderer: (row) => (row.hours % 1 === 0 ? parseInt(row.hours) : row.hours),
      fieldProps: {
        label: 'Órák',
        InputProps: { inputProps: { min: 0, step: 0.1 } }
      },
      rule: yup.number().min(0, 'Az órák száma nem lehet negatív')
    },
    {
      name: 'usage',
      showInTable: true,
      cellRenderer: (row) => (
        <Tooltip
          placement='top'
          arrow
          title={`${getHourAndMinutesFromMinutes(row.minutesUsed)} / ${getHourAndMinutesFromMinutes(
            row.hours * 60 - row.minutesUsed
          )}`}
        >
          <Box>
            <LinearProgress
              variant='determinate'
              value={(row.minutesUsed / (row.hours * 60)) * 100}
              sx={{ borderRadius: 2, height: '0.5rem', width: { xs: '100px' } }}
            />
          </Box>
        </Tooltip>
      ),
      fieldProps: {
        label: 'Felhasználás'
      }
    },
    {
      name: 'status',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='subscription' />,
      fieldProps: {
        label: 'Státusz'
      }
    }
  ];
};

export default useSubscriptionFields;
