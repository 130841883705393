import { Button, Dialog } from 'ui';
import { Stack } from '@mui/material';
import { useState } from 'react';
import useCourseApi from './useCourseApi';
import useTranslation from 'hooks/useTranslation';
import { useCourseContext } from './CourseContext';

const DiscountDeletionDialog = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  const { course, refetchCourse } = useCourseContext();

  const { t } = useTranslation();
  const { deleteCourseContinuityDiscount } = useCourseApi();

  const handleSubmit = async () => {
    setLoading(true);
    await deleteCourseContinuityDiscount(course.uid);
    setLoading(false);

    refetchCourse();
    onClose();
  };

  return (
    <Dialog title={t('courses.delete-continuity-discount')} isOpen={isOpen} onClose={onClose} hideActions>
      {t('courses.delete-continuity-discount-warning')}
      <Stack spacing={2} direction='row' justifyContent={'flex-end'} sx={{ flexGrow: 1 }}>
        <Button color='secondary' variant='text' onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button disabled={loading} loading={loading} onClick={handleSubmit}>
          {t('common.delete-item')}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default DiscountDeletionDialog;
