import React, { useEffect, useState } from 'react';
import { Form } from '../../ui';
import useEventFormFields from './useEventFormFields';
import useEventApi from './useEventApi';
import useTranslation from '../../hooks/useTranslation';
import { localIsoStringToUtcTimestamp } from 'utils/date';
import { getYupSchema } from 'ui/Form/FormUtils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const EventForm = ({ course, onCancel, onSave, selectedEvent }) => {
  const [eventLocationType, setEventLocationType] = useState(selectedEvent?.event?.extendedProps?.courseEventLocationType);
  const eventFields = useEventFormFields(eventLocationType);
  const [loading, setLoading] = useState(false);
  const isEditMode = selectedEvent?.event?.id;
  const calendarApi = selectedEvent?.view?.calendar;
  const { createEvent, updateEvent, deleteEvent } = useEventApi();
  const { t } = useTranslation();
  
  const schema = getYupSchema(eventFields);
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { watch, setValue } = methods;
  const currentLocationType = watch('courseEventLocationType');
  const currentOnlineLocation = watch('courseEventOnlineLocation');
  const currentPersonalLocation = watch('courseEventPersonalLocation');

  useEffect(() => {
    switch(currentLocationType) {
      case 'mixed': 
        if (!currentOnlineLocation && course.online_location) {
          setValue('courseEventOnlineLocation', course.online_location);
        }
        if (!currentPersonalLocation && course.personal_location) {
          setValue('courseEventPersonalLocation', course.personal_location);
        }
        break;
      case 'online':
        if (!currentOnlineLocation && course.online_location) {
          setValue('courseEventOnlineLocation', course.online_location);
        }
        if (currentPersonalLocation.length > 0) {
          setValue('courseEventPersonalLocation', '');
        }
        setValue('personal_capacity', '');
        break;
      case 'personal':
        if (!currentPersonalLocation && course.personal_location) {
          setValue('courseEventPersonalLocation', course.personal_location);
        }
        if (currentOnlineLocation.length > 0) {
          setValue('courseEventOnlineLocation', '');
        }
        setValue('online_capacity', '');
    }
    if (currentLocationType !== eventLocationType) {
      setEventLocationType(currentLocationType);
    }
  }, [currentLocationType, course]);

  const defaultValues = {
    online_capacity: selectedEvent?.event?.extendedProps?.online_capacity,
    personal_capacity: selectedEvent?.event?.extendedProps?.personal_capacity,
    teacher_ids: selectedEvent?.event?.extendedProps?.teacher_ids,
    courseEventLocationType: selectedEvent?.event?.extendedProps?.courseEventLocationType,
    courseEventOnlineLocation: selectedEvent?.event?.extendedProps?.courseEventOnlineLocation,
    courseEventPersonalLocation: selectedEvent?.event?.extendedProps?.courseEventPersonalLocation
  };

  const handleSubmit = async (fields) => {
    setLoading(true);

    if (isEditMode) {
      await updateEvent(isEditMode, {
        start: localIsoStringToUtcTimestamp(selectedEvent?.event?.startStr),
        end: localIsoStringToUtcTimestamp(selectedEvent?.event?.endStr),
        online_capacity: fields.online_capacity,
        personal_capacity: fields.personal_capacity,
        course_id: course.uid,
        teacher_ids: fields.teacher_ids,
        location_type: fields.courseEventLocationType,
        online_location: fields.courseEventOnlineLocation,
        personal_location: fields.courseEventPersonalLocation
      });
    } else {
      await createEvent({
        start: localIsoStringToUtcTimestamp(selectedEvent?.startStr),
        end: localIsoStringToUtcTimestamp(selectedEvent?.endStr),
        online_capacity: fields.online_capacity,
        personal_capacity: fields.personal_capacity,
        course_id: course.uid,
        teacher_ids: fields.teacher_ids,
        location_type: fields.courseEventLocationType,
        online_location: fields.courseEventOnlineLocation,
        personal_location: fields.courseEventPersonalLocation
      });
      console.log(selectedEvent?.startStr);
    }

    if (calendarApi) {
      calendarApi.unselect(); // clear date selection
      calendarApi.refetchEvents();
    }

    onSave();
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteEvent(isEditMode);
    setLoading(false);
    if (calendarApi) {
      calendarApi.unselect(); // clear date selection
      calendarApi.refetchEvents();
    }

    onSave();
  };

  return (
    <Form
      fields={eventFields}
      externalMethods={methods}
      onSubmit={handleSubmit}
      onExtraAction={isEditMode ? () => handleDelete() : null}
      extraActionLabel='Törlés'
      onAltAction={onCancel}
      submitLabel={isEditMode ? t('calendar.update-event') : t('calendar.create-new-event')}
      loading={loading}
      defaultValues={defaultValues}
      sx={{ mt: 2 }}
    />
  );
};

export default EventForm;
