import { Box, Container, Typography, Stack } from '@mui/material';
import background01 from '../../images/auth-bg-000.webp';
import background02 from '../../images/auth-bg-001.webp';
import background03 from '../../images/auth-bg-002.webp';
import background04 from '../../images/auth-bg-003.webp';
import logo from '../../images/logo.svg';
import barionImg from 'images/barion.png';

const slides = [
  {
    background: background04,
    title:
      'Mivel kevés szabadidőm van, az egyik fő szempont számomra az volt, hogy tudjak munka mellett tanulni. Bármikor jöhetek, a saját tempómban tudok haladni, nem kell másokhoz alkalmazkodnom.',
    description: 'Andi'
  },
  {
    background: background01,
    title: 'Baráti hangulat, felkészült, lendületes csapat. Beszédközpontú, modern oktatás.',
    description: 'Andi'
  },
  {
    background: background03,
    title:
      'Tökéletes oktatási forma. Csak ajánlani tudom, mert te alakíthatod ki az órarendedet, és abban a tempóban haladhatsz, ami neked megfelel.',
    description: 'Gabi'
  },
  {
    background: background02,
    title:
      'Az utóbbi 10 hónapban rengeteg segítséget kaptam az Up2You-ban. A tanárok kedvesek, segítőkészek, és komolyan veszik, hogy elérjem a kitűzött célomat, a sikeres IELTS vizsgát. Nagyszerű nyelviskola családias hangulattal - csak ajánlani tudom mindenkinek!',
    description: 'Gergő'
  }
];

const AuthPageLayout = ({ children, title, description }) => {
  var slide = slides[Math.floor(Math.random() * slides.length)];
  return (
    <Stack
      direction='row'
      sx={{
        width: '100vw',
        height: '100dvh',
        overflow: 'hidden',
        borderTopColor: 'primary.main',
        borderTopWidth: '10px',
        borderTopStyle: 'solid'
      }}
    >
      <Box
        sx={{
          flexBasis: '100%',
          height: '100vh',
          maxHeight: '100dvh',
          justifyContent: 'flex-start',
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
      >
        <Container
          component='main'
          maxWidth='xs'
          sx={{
            height: 'auto',
            flexGrow: { xs: 0, lg: 1 },
            paddingTop: { xs: 4, lg: 0 },
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ mb: 4 }}>
            <img src={logo} height={40} />
          </Box>
          <Typography component='h1' variant='h3' sx={{ mb: 1 }}>
            {title}
          </Typography>
          {description && (
            <Typography component='p' variant='body1' sx={{ mb: 4 }}>
              {description}
            </Typography>
          )}
          <Box>{children}</Box>
        </Container>
        <Box
          sx={{
            paddingTop: 8,
            paddingBottom: 2,
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          <img src={barionImg} alt='barion' width='220' />
          <Typography variant='body2' sx={{ mt: 1, color: '#00000060' }}>
            2024 Copyright - Up2You Nyelviskola
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flexBasis: '100%',
          backgroundColor: 'background.default',
          backgroundImage: `url(${slide.background})`,
          backgroundPosition: 'bottom, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: { lg: 'flex', xs: 'none' },
          justifyContent: 'end',
          flexDirection: 'column',
          padding: '0 4rem 4rem'
        }}
      >
        <Box
          sx={{
            background: '#00000030',
            backdropFilter: 'blur(15px)',
            padding: 4,
            border: '1px solid #00000010',
            borderRadius: '10px'
          }}
        >
          <Typography component='h2' variant='h4' sx={{ mb: 2, color: 'white' }}>
            &ldquo;{slide.title}&rdquo;
          </Typography>
          <Typography component='p' variant='h6' sx={{ color: 'white' }}>
            - {slide.description}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default AuthPageLayout;
