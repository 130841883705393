import useTranslation from 'hooks/useTranslation';
import * as yup from 'yup';

const useContinuityDiscountFormFields = () => {
  const { t } = useTranslation();
  return [
    {
      name: 'continuityDiscount1',
      type: 'text',
      fieldProps: {
        label: '1. hónap',
        helperText: t('helpertext.discount')
      },
      rule: yup
        .string()
        .test('number-or-percentage', 'A kedvezmény összegszerű vagy százalékos megadása szükséges', (value) => {
          const pattern = /^(?:100\s*%|\d{1,2}\s*%|\d+)$/gm;
          return pattern.test(value);
        })
    },
    {
      name: 'continuityDiscount2',
      type: 'text',
      fieldProps: {
        label: '2. hónap',
        helperText: t('helpertext.discount')
      },
      rule: yup
        .string()
        .test('number-or-percentage', 'A kedvezmény összegszerű vagy százalékos megadása szükséges', (value) => {
          const pattern = /^(?:100\s*%|\d{1,2}\s*%|\d+)$/gm;
          return pattern.test(value);
        })
    },
    {
      name: 'continuityDiscount3',
      type: 'text',
      fieldProps: {
        label: '3. hónap',
        helperText: t('helpertext.discount')
      },
      rule: yup
        .string()
        .test('number-or-percentage', 'A kedvezmény összegszerű vagy százalékos megadása szükséges', (value) => {
          const pattern = /^(?:100\s*%|\d{1,2}\s*%|\d+)$/gm;
          return pattern.test(value);
        })
    }
  ];
};

export default useContinuityDiscountFormFields;
