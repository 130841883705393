import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import useTranslation from 'hooks/useTranslation';

const ConnectedItemsList = ({ icon, values }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button startIcon={icon} aria-controls='menu' aria-haspopup='true' onClick={handleClick} size='small'>
        {t('common.list-items')}
      </Button>
      <Menu id='menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} elevation={10}>
        {values.sort((a, b) => a.value.localeCompare(b.value)).map((v) => (
          <MenuItem key={v.key} style={{ cursor: 'default' }} disableRipple onClick={(e) => e.stopPropagation()}>
            {v.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ConnectedItemsList;
