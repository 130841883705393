import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import React, { forwardRef } from 'react';

const Select = forwardRef(function Select({ options, label, fullWidth, ...props }, ref) {
  const labelId = `component-${label}`;
  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel shrink label={labelId}>
          {label}
        </InputLabel>
      )}
      <MuiSelect label={label} labelId={labelId} notched {...props} ref={ref}>
        {options.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
});

export default Select;
