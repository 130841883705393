import TablePage from '../../components/Common/TablePage';
import useSubscriptionFields from '../../components/Subscription/useSubscriptionFields';
import useSubscriptionApi from '../../components/Subscription/useSubscriptionApi';
import useSubscriptionFilters from '../../components/Subscription/useSubscriptionFilters';
import useViewer from 'components/Viewer/ViewerContext/useViewer';

const SubscriptionsPage = () => {
  const { getSubscriptions, updateSubscription } = useSubscriptionApi();
  const fields = useSubscriptionFields();
  const filtersConfig = useSubscriptionFilters();
  const { viewerRoles, viewerHasRole } = useViewer();

  return (
    <TablePage
      fields={fields}
      onGetRows={getSubscriptions}
      onUpdateRow={viewerHasRole(viewerRoles.ADMIN) ? updateSubscription : null}
      filterConfig={filtersConfig}
    />
  );
};

export default SubscriptionsPage;
