import ConfirmButton from '../../ui/ConfirmButton/ConfirmButton';
import { useState } from 'react';
import useNotesApi from './useNotesApi';

export const CompleteTodoButton = ({ noteId, lastModification, setRefetchTrigger }) => {
  const { completeNote } = useNotesApi();
  const [isLoading, setIsLoading] = useState(false);
  const handleComplete = async () => {
    setIsLoading(true);
    await completeNote(noteId, lastModification);
    setRefetchTrigger((prev) => prev + 1);
    setIsLoading(false);
  };
  return (
    <ConfirmButton
      onConfirm={handleComplete}
      variant='outlined'
      size='small'
      title={isLoading ? 'Mentés...' : 'Készre állítás'}
      disabled={isLoading}
      sx={{ whiteSpace: 'nowrap' }}
    />
  );
};
