import useTranslation from 'hooks/useTranslation';
import * as yup from 'yup';

const useStudentCoursesFields = () => {
  const { t } = useTranslation();

  return [
    {
      name: 'name',
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'weight',
      type: 'number',
      cellRenderer: (row) => row.weight || 1,
      showInTable: true,
      fieldProps: {
        label: 'Súly',
        step: '0.1'
      },
      rule: yup.number()
    },
    {
      name: 'fixDiscount',
      type: 'text',
      cellRenderer: (row) => row.fixDiscount || '-',
      showInTable: true,
      fieldProps: {
        label: 'Fix kedvezmény',
        helperText: t('helpertext.discount')
      },
      rule: yup
        .string()
        .test('number-or-percentage', 'A fix kedvezmény összegszerű vagy százalékos megadása szükséges', (value) => {
          if (!value) return true;
          const pattern = /^(?:100\s*%|\d{1,2}\s*%|\d+)$/gm;
          return pattern.test(value);
        })
    }
  ];
};

export default useStudentCoursesFields;
