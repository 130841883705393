import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { useEffect, useState } from 'react';
import { Button } from 'ui';
import useEntryApi from './useEntryApi';
import { useCourseContext } from 'components/Course/CourseContext';
import UpcomingEventCard from 'components/Event/UpcomingEventCard';

const NextEntry = ({ onCalendarClick }) => {
  const { t } = useTranslation();
  const [entry, setEntry] = useState();
  const [loading, setLoading] = useState(true);
  const { getNextEntry } = useEntryApi();
  const { course } = useCourseContext();

  const fetchNextEntry = async (uid) => {
    const response = await getNextEntry(uid);
    setEntry(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchNextEntry(course.uid);
  }, [course.uid]);

  const handleJoin = (location) => {
    window.open(location, '_blank');
  };

  // const handleCopyUrl = () => {
  //   navigator.clipboard.writeText(entry.location);
  //   enqueueSnackbar(t('common.copied-to-clipboard'), { variant: 'success' });
  // };

  // const handleDelete = async () => {
  //   setLoading(true);
  //   await deleteEntry(entry.id);
  //   fetchNextEntry(course.uid);
  //   refetchCourse();
  // };

  if (loading) {
    return <Skeleton variant='rounded' width={'100%'} height={104} />;
  }

  // if (subscriptions.length > 0) {
  //   return null;
  // }

  if (!entry) {
    return (
      <Card>
        <CardContent>
          <Typography variant='subtitle2'>{t('courses.next-entry')}</Typography>
          <Typography variant='h5' sx={{ mt: 4 }}>
            {t('courses.no-next-entry')}
          </Typography>
          <Typography variant='body2'>{t('courses.no-next-entry-details')}</Typography>
          <Button variant='contained' color='primary' onClick={onCalendarClick} fullWidth sx={{ mt: 4 }}>
            {t('courses.book-entry')}
          </Button>
        </CardContent>
      </Card>
    );
  }

  return <UpcomingEventCard event={entry} handleJoin={handleJoin} />;
};

export default NextEntry;
