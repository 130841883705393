import { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  Link as MaterialLink,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio
} from '@mui/material';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import Spinner from 'ui/Spinner/Spinner';
import useOptions from 'components/Options/OptionsContext/useOptions';
import { Button } from 'ui';
import useApi from '../../api/useApi';
import barionImg from 'images/barion.png';
import StudentInvoiceDetails from 'components/Student/StudentInvoiceDetails';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { validateInvoiceFields } from 'utils/invoice';
import useTranslation from '../../hooks/useTranslation';
import StudentTransferInformation from '../../components/Student/StudentTransferInformation';
import useTransactionApi from '../../components/Transactions/useTransactionApi';

const CartPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [product, setProduct] = useState();
  const [accepted, setAccepted] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('Barion');
  const [transferConfirmed, setTransferConfirmed] = useState(false);
  const { getOptionValue } = useOptions();
  const { id } = useParams();
  const { api } = useApi();
  const { viewer } = useViewer();
  const [validInvoiceFields, setValidInvoiceFileds] = useState(false);
  const { createTransaction } = useTransactionApi({});

  const getSubscriptionType = async (id) => {
    api({
      url: `/product/${id}`,
      method: 'get'
    })
      .then(({ data }) => {
        setProduct(data);
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubscriptionType(id);
  }, [id]);

  const handlePayment = () => {
    setPaymentLoading(true);
    api({
      url: '/subscription',
      data: {
        subscriptionTypeId: id
      },
      method: 'post'
    })
      .then(({ data }) => {
        if (data?.PaymentRedirectUrl) {
          window.location = data.PaymentRedirectUrl;
        }
        setPaymentLoading(false);
      })
      .catch(function () {
        setPaymentLoading(false);
      });
  };

  const handleTransfer = async () => {
    // NOTE: I am not if it is necessary to create a new endpoint for this
    // TODO: need a different endpoint for this
    setPaymentLoading(true);
    await createTransaction({
      subscriptionTypeId: product.subscription.uid,
      studentId: null,
      transactionType: 'Transfer',
      createInvoice: true,
      hours: product.subscription.hours
    });
    setTransferConfirmed(true);
    setPaymentLoading(false);
  };

  const handleValidateInvoiceFields = (fields) => {
    setValidInvoiceFileds(validateInvoiceFields(fields));
  };

  const isSubmitDisabled = useMemo(() => !accepted || !validInvoiceFields, [accepted, validInvoiceFields]);

  if (transferConfirmed) {
    return (
      <Card sx={{ maxWidth: '28rem', margin: '0 auto', p: 4, textAlign: 'center' }}>
        <StudentTransferInformation studentId={viewer.id} />
        <Box sx={{ mt: 4 }}>
          <MaterialLink component={Link} color='text.secondary' to={`/courses/${product.subscription.course_id}`}>
            vissza a tanfolyamhoz
          </MaterialLink>
        </Box>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: '28rem', margin: '0 auto', p: 4, textAlign: 'center' }}>
      {loading && (
        <>
          <Spinner size={50} sx={{ my: 1.4 }} />
          <Typography variant='h5' sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
            Tranzakció előkészítése
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            Egy kis türlemet kérünk amig előkészítjük a tranzakciót.
          </Typography>
        </>
      )}
      {!loading && product && (
        <>
          <ShoppingCartTwoToneIcon sx={{ fontSize: '4rem', color: 'primary.main' }} />
          <Typography variant='h5' sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
            Bérlet vásárlás
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center' }}>
            A fizetés előtt kérjük ellenőrizd a bérlet adatait.
          </Typography>

          <Divider sx={{ my: 4 }} />
          <Typography variant='h4' sx={{ textAlign: 'center' }}>
            {parseInt(product.subscription.discountedPrice).toLocaleString('hu')} Ft
          </Typography>
          <Divider sx={{ my: 4 }} />
          <Stack gap={1}>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam:
              </Typography>
              <Typography variant='body1'>{product.course.name}</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Órák:
              </Typography>
              <Typography variant='body1'>{product.subscription.hours} óra</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Nyelv:
              </Typography>
              <Typography variant='body1'>{getOptionValue('languages', product.course.language)}</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam típusa:
              </Typography>
              <Typography variant='body1'>{getOptionValue('courseGroupTypes', product.course.groupType)}</Typography>
            </Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='baseline'>
              <Typography variant='body2' color='text.secondary'>
                Tanfolyam helyszíne:
              </Typography>
              <Typography variant='body1'>
                {getOptionValue('courseLocationTypes', product.course.locationType)}
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{ my: 4 }} />

          <StudentInvoiceDetails studentId={viewer.id} justifyBetween onChange={handleValidateInvoiceFields} />
          {!validInvoiceFields && (
            <Typography variant='body2' color='error.main' sx={{ textAlign: 'center', mt: 1, mb: 2 }}>
              Hiányos számlázási adatok
            </Typography>
          )}

          <FormControl sx={{ mt: 4 }}>
            <FormLabel id='demo-row-radio-buttons-group-label'>{t('courses.paymentMethod')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <FormControlLabel value='Barion' control={<Radio />} label={t('courses.paymentMethod-card')} />
              <FormControlLabel value='Transfer' control={<Radio />} label={t('courses.paymentMethod-transfer')} />
            </RadioGroup>
          </FormControl>

          <Box sx={{ mb: 1, mt: 4 }}>
            <FormControlLabel
              control={<Checkbox onChange={(e) => setAccepted(e.target.checked)} />}
              label={
                <>
                  Az{' '}
                  <MaterialLink component={Link} target='_blank' to='//up2you.hu/aszf'>
                    ÁSZF
                  </MaterialLink>
                  -et megismertem és elfogadom
                </>
              }
            />
          </Box>

          {paymentMethod === 'Barion' && (
            <>
              <Button onClick={handlePayment} loading={paymentLoading} disabled={isSubmitDisabled}>
                Tovább a fizetéshez
              </Button>

              <Box sx={{ mt: 2 }}>
                <img src={barionImg} alt='barion' width='260' />
              </Box>
            </>
          )}

          {paymentMethod === 'Transfer' && (
            <>
              <Button onClick={handleTransfer} loading={paymentLoading} disabled={isSubmitDisabled}>
                Bérlet megvásárlása
              </Button>
            </>
          )}

          <Divider sx={{ my: 4 }} />
          <Typography variant='body2' color='text.secondary' sx={{ textAlign: 'center', mt: 1, mb: 2 }}>
            vagy{' '}
            <MaterialLink component={Link} color='text.secondary' to={`/courses/${product.subscription.course_id}`}>
              vissza a tanfolyamhoz
            </MaterialLink>
          </Typography>
        </>
      )}
    </Card>
  );
};

export default CartPage;
