import useOptions from 'components/Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useMemo } from 'react';
import { getDayInUnixTimestamp } from 'utils/date';
import moment from 'moment';

const useNoteFilters = (studentId, showTodoFilters) => {
  const { courses} = useOptions();
  const [searchParams] = useSearchParams();
  return useMemo(() => [
    {
      id: 'student',
      filterInitialValue: studentId || '',
      filterType: 'hidden',
    },
    {
      id: 'course',
      label: 'Tanfolyam',
      filterInitialValue: searchParams.get('course') || '',
      filterValue: '',
      filterOptions: courses,
      filterType: 'select',
      icon: BookOutlinedIcon
    },
    {
      id: 'noteType',
      label: 'Jegyzet jellege',
      filterInitialValue: searchParams.get('noteType') || '',
      filterValue: '',
      filterOptions: [
        { key: 'todo', value: 'TODO' },
        { key: 'common', value: 'Általános' }
      ],
      filterType: 'select',
      icon: EditNoteRoundedIcon,
    },
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: [
        { key: 'done', value: 'Kész' },
        { key: 'actual', value: 'Aktuális' },
        { key: 'expired', value: 'Lejárt' },
        { key: 'planned', value: 'Tervezett' },
      ],
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon,
      isVisible: (filters) => {
        return filters.find(f => f.id === 'noteType')?.filterValue === 'todo'
      }
    },
    {
      id: 'ddlFrom',
      label: 'Határidő',
      filterInitialValue: searchParams.get('ddlFrom') || getDayInUnixTimestamp(moment().subtract(1, 'months').toDate()).toString(),
      filterValue: '',
      filterType: 'date',
      isVisible: (filters) => {
        return filters.find(f => f.id === 'noteType')?.filterValue === 'todo'
      }
    },
    {
      id: 'ddlTo',
      label: 'Határidő',
      filterInitialValue: searchParams.get('ddlTo') || getDayInUnixTimestamp(new Date()).toString(),
      filterValue: '',
      filterType: 'date',
      isVisible: (filters) => {
        return filters.find(f => f.id === 'noteType')?.filterValue === 'todo'
      }
    },
    {
      id: 'content',
      label: 'Tartalom',
      filterInitialValue: searchParams.get('content') || '',
      filterValue: '',
      filterType: 'search'
    }
  ], [studentId, showTodoFilters]);
};

export default useNoteFilters;
