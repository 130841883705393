import * as yup from 'yup';
import useOptions from '../Options/OptionsContext/useOptions';

const useEventFormFields = (eventLocationType) => {
  const { teachers, courseLocationTypes } = useOptions();
  const shouldDisplayPersonal = ['mixed', 'personal'].includes(eventLocationType);
  const shouldDisplayOnline = ['mixed', 'online'].includes(eventLocationType);
  return [
    {
      name: 'teacher_ids',
      type: 'select',
      fieldProps: {
        label: 'Tanár',
        options: teachers,
        multiselect: true
      },
      rule: yup.array().test({
        message: 'Legalább egy tanárt ki kell választani!',
        test: (arr) => arr.length > 0
      })
    },
    {
      name: 'courseEventLocationType',
      type: 'select',
      fieldProps: {
        label: 'Helyszín típusa',
        options: courseLocationTypes
      },
      rule: yup
        .string()
        .required('A helyszín típusának megadása kötelező!')
        .oneOf(courseLocationTypes.map((type) => type.key))
    },
    {
      name: 'courseEventOnlineLocation',
      type: shouldDisplayOnline ? 'text' : 'hidden',
      fieldProps: {
        label: 'Online helyszín'
      },
      rule: yup.string().when('courseEventLocationType', {
        is: (val) => ['online', 'mixed'].includes(val),
        then: yup.string().trim().required('Online helyszín megadása kötelező, ha a helyszín típusa online vagy vegyes.')
      })
    },
    {
      name: 'courseEventPersonalLocation',
      type: shouldDisplayPersonal ? 'text' : 'hidden',
      fieldProps: {
        label: 'Személyes helyszín'
      },
      rule: yup.string().when('courseEventLocationType', {
        is: (val) => ['personal', 'mixed'].includes(val),
        then: yup.string().trim().required('Személyes helyszín megadása kötelező, ha a helyszín típusa személyes vagy vegyes.')
      })
    },
    {
      name: 'online_capacity',
      type: shouldDisplayOnline ? 'number' : 'hidden',
      fieldProps: {
        label: 'Online kapacitás',
      },
      rule: yup.mixed().when('courseEventLocationType', {
        is: (val) => ['online', 'mixed'].includes(val),
        then: yup.number().min(1, 'A kapacitás legalább 1 kell legyen').typeError('Online kapacitás megadása kötelező, ha a helyszín típusa online vagy vegyes.').required('Online kapacitás megadása kötelező, ha a helyszín típusa online vagy vegyes.')
      })
    },
    {
      name: 'personal_capacity',
      type: shouldDisplayPersonal ? 'number' : 'hidden',
      fieldProps: {
        label: 'Személyes kapacitás',
      },
      rule: yup.mixed().when('courseEventLocationType', {
        is: (val) => ['personal', 'mixed'].includes(val),
        then: yup.number().min(1, 'A kapacitás legalább 1 kell legyen').typeError('Személyes kapacitás megadása kötelező, ha a helyszín típusa személyes vagy vegyes.').required('Személyes kapacitás megadása kötelező, ha a helyszín típusa személyes vagy vegyes.')
      })
    },
  ];
};

export default useEventFormFields;
