import useOptions from 'components/Options/OptionsContext/useOptions';
import * as yup from 'yup';
import useTranslation from 'hooks/useTranslation';

const useCreateNoteFields = () => {
  const { courses } = useOptions();
  const { t } = useTranslation()
  return [
    {
      name: 'content',
      type: 'text',
      fieldProps: {
        label: 'Tartalom',
        multiline: true,
      },
      rule: yup.string().trim().required('Tartalom megadása kötelező')
    },
    {
      name: 'course',
      type: 'select',
      fieldProps: {
        label: 'Kapcsolódó tanfolyam',
        options: courses,
      },
      rule: yup.string().nullable()
    },
    {
      name: 'deadline',
      type: 'date',
      fieldProps: {
        label: 'Határidő',
        helperText: t('helpertext.note-deadline')
      },
      rule: yup.string().nullable()
    },
    {
      name: 'important',
      type: 'checkbox',
      fieldProps: {
        label: 'Csillagozott',
      },
      rule: yup.boolean()
    },
  ];
};

export default useCreateNoteFields;
