import React, { useCallback, useState } from 'react';
import OptionsContext from './OptionsContext';
import useApi from 'api/useApi';
import { useError } from 'hooks/useError';

const OptionsContextProvider = ({ children }) => {
  const [options, setOptions] = useState();
  const { api } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getLanguage = useCallback(
    (key) => {
      const currentOption = options?.languages.find((item) => {
        return item.key === key;
      });
      return currentOption?.value || '';
    },
    [options]
  );

  const getOption = useCallback(
    (option, key) => {
      const currentOption = options?.[option].find((item) => {
        return item.key === key;
      });
      return currentOption;
    },
    [options]
  );

  const getOptionValue = useCallback(
    (option, key) => {
      const currentOption = options?.[option].find((item) => {
        return item.key === key;
      });
      return currentOption?.value || '';
    },
    [options]
  );

  const renderOptionList = (values, options) => {
    if (!values) {
      return '';
    }
    const stringifiedValues = values.map((v) => v.toString());
    const selectedOptions = options.filter((option) => stringifiedValues.includes(option.key));
    return selectedOptions.map((option) => option.value).join(', ');
  };

  // this method should be called whenever an entity present in the options is updated, created or deleted
  const refetchOptions = async () => {
    try {
      const { data } = await api({
        url: '/auth/options',
        method: 'get'
      });
      setOptions(data);
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const sortOptions = (options) => options.sort((a, b) => a.value.localeCompare(b.value));

  return (
    <OptionsContext.Provider
      value={{
        getOption,
        getOptionValue,
        setOptions,
        languages: sortOptions(options?.languages || []),
        getLanguage,
        userStatuses: sortOptions(options?.userStatuses || []),
        paymentProfiles: sortOptions(options?.paymentProfiles || []),
        courses: sortOptions(options?.courses || []),
        courseStatuses: sortOptions(options?.courseStatuses || []),
        subscriptionStatuses: sortOptions(options?.subscriptionStatuses || []),
        entryStatuses: sortOptions(options?.entryStatuses || []),
        roles: sortOptions(options?.roles || []),
        teachers: sortOptions(options?.teachers || []),
        courseGroupTypes: sortOptions(options?.courseGroupTypes || []),
        courseLocationTypes: sortOptions(options?.courseLocationTypes || []),
        transactionStatuses: sortOptions(options?.transactionStatuses || []),
        transactionTypes: sortOptions(options?.transactionTypes || []),
        students: sortOptions(options?.students || []),
        renderOptionList,
        refetchOptions
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

export default OptionsContextProvider;
