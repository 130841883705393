import TablePage from 'components/Common/TablePage';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useNotesApi from './useNotesApi';
import useTodoFields from './useTodoFields';
import { useState } from 'react';
import useTodoFilters from './useTodoFilters';

const TodoTable = () => {
  const { getNotes, updateNote, deleteNote } = useNotesApi();
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const fields = useTodoFields(setRefetchTrigger);
  const { viewerHasRole, viewerRoles } = useViewer();
  const filterConfig = useTodoFilters();

  return (
    <TablePage
      fields={fields}
      onGetRows={getNotes}
      onUpdateRow={viewerHasRole(viewerRoles.OPERATOR) ? updateNote : null}
      onDeleteRow={viewerHasRole(viewerRoles.OPERATOR) ? deleteNote : null}
      filterConfig={filterConfig}
      refetchTrigger={refetchTrigger}
    />
  );
};

export default TodoTable;
