import React from 'react';
import { Box, Chip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import PersonalVideoRoundedIcon from '@mui/icons-material/PersonalVideoRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import useOptions from '../Options/OptionsContext/useOptions';
import CourseAdminMeta from './CourseAdminMeta';
import useViewer from '../Viewer/ViewerContext/useViewer';
import DiscountStatusChip from './DiscountStatusChip';

const iconSx = {};

export const icons = {
  language: <LanguageIcon fontSize='tiny' sx={iconSx} />,
  group: <Groups2RoundedIcon fontSize='tiny' sx={iconSx} />,
  individual: <Person2RoundedIcon fontSize='tiny' sx={iconSx} />,
  online: <PersonalVideoRoundedIcon fontSize='tiny' sx={iconSx} />,
  personal: <BusinessRoundedIcon fontSize='tiny' sx={iconSx} />,
  mixed: <QuestionMarkRoundedIcon fontSize='tiny' sx={iconSx} />
};

const CourseMeta = ({ course, sx }) => {
  const { getOptionValue } = useOptions();
  const { viewerHasRole, viewerRoles } = useViewer();

  return (
    <Box sx={{ ...sx, display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
      {course.language && (
        <Chip size='small' icon={icons.language} label={getOptionValue('languages', course.language)} />
      )}

      {course.groupType && (
        <Chip
          size='small'
          icon={icons[course.groupType]}
          label={getOptionValue('courseGroupTypes', course.groupType)}
        />
      )}

      {course.locationType && (
        <Chip
          size='small'
          icon={icons[course.locationType]}
          label={getOptionValue('courseLocationTypes', course.locationType)}
        />
      )}

      {viewerHasRole(viewerRoles.TEACHER) && <CourseAdminMeta course={course} />}
      {viewerHasRole(viewerRoles.ADMIN) && <DiscountStatusChip course={course} />}
    </Box>
  );
};

export default CourseMeta;
