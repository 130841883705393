import { Chip } from '@mui/material';

const statuses = {
  user: {
    0: { label: 'Inaktív', color: 'primary' },
    1: { label: 'Megerősítésre vár', color: 'warning' },
    2: { label: 'Aktív', color: 'success' },
    9: { label: 'Archivált', color: 'default' }
  },
  course: {
    0: { label: 'Inaktív', color: 'primary' },
    1: { label: 'Aktív', color: 'success' },
    9: { label: 'Archivált', color: 'default' }
  },
  entry: {
    booked: { label: 'Foglalt', color: 'info' },
    attended: { label: 'Teljesített', color: 'success' },
    missed: { label: 'Hiányzott', color: 'error' }
  },
  language: {
    inactive: { label: 'Inaktív', color: 'default' },
    active: { label: 'Aktív', color: 'success' }
  },
  subscription: {
    inactive: { label: 'Inaktív', color: 'default' },
    active: { label: 'Aktív', color: 'success' }
  },
  coupon: {
    expired: { label: 'Lejárt', color: 'default' },
    active: { label: 'Aktív', color: 'success' }
  },
  note: {
    done: { label: 'Kész', color: 'success' },
    actual: { label: 'Aktuális', color: 'warning' },
    expired: { label: 'Lejárt', color: 'error' },
    planned: { label: 'Tervezett', color: 'default' },
  }
};

const StatusChip = ({ status, entity, ...props }) => {
  const currentStatus = statuses[entity][status];
  return (
    <>{currentStatus && <Chip label={currentStatus.label} size='small' color={currentStatus.color} {...props} />}</>
  );
};

export default StatusChip;
