import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const useCouponFilters = () => {
  const { courses, students } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'course',
      label: 'Tanfolyam',
      filterInitialValue: searchParams.get('course') || '',
      filterValue: '',
      filterOptions: courses,
      filterType: 'select',
      icon: BookOutlinedIcon
    },
    {
      id: 'student',
      label: 'Tanuló',
      filterInitialValue: searchParams.get('student') || '',
      filterValue: '',
      filterOptions: students,
      filterType: 'select',
      icon: PeopleOutlineOutlinedIcon
    },
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: [
        { key: 'active', value: 'Aktív' },
        { key: 'expired', value: 'Lejárt' }
      ],
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useCouponFilters;
