import { Card, Grid, TablePagination } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { CaptionValue } from './caption-value';

export const TableForMobile = ({
  fields,
  rows,
  idField,
  onUpdateRow,
  onDeleteRow,
  onRowClick,
  handleRowClick,
  rowsPerPageValues,
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction='column'>
      {rows.map((row) => (
        <Card
          key={row[idField]}
          sx={{
            cursor: (onUpdateRow || onDeleteRow || onRowClick) && !parseInt(row.locked) ? 'pointer' : 'default',
            mb: '8px',
            p: '5%'
          }}
          onClick={() => handleRowClick(row)}
        >
          <Grid container spacing={0.5}>
            {fields
              .filter((field) => field.showInTable)
              .map((field) => (
                <Grid item xs={12} key={field.name}>
                  <CaptionValue
                    caption={field.fieldProps.label}
                    value={field.cellRenderer ? field.cellRenderer(row) : row[field.name]}
                  />
                </Grid>
              ))}
          </Grid>
        </Card>
      ))}
      <TablePagination
        rowsPerPageOptions={rowsPerPageValues}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          alignSelf: 'center',
          '& .MuiToolbar-root .MuiTablePagination-actions': {
            ml: '0px'
          }
        }}
        labelRowsPerPage={t('table.rows-per-page')}
      />
    </Grid>
  );
};
