import { Chip, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DiscountChangeDialog from './DiscountChangeDialog';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DiscountDeletionDialog from './DiscountDeletionDialog';
import useTranslation from 'hooks/useTranslation';
import { useCourseContext } from './CourseContext';

const DiscountStatusChip = ({ course }) => {
  const [isModDialogOpen, setModDialogOpen] = useState(false);
  const [isDelDialogOpen, setDelDialogOpen] = useState(false);
  const courseContext = useCourseContext();
  const { t } = useTranslation();

  const hasContinuityDiscount = course.continuityDiscount1 != null;

  const handleCloseDialog = () => {
    setModDialogOpen(false);
    setDelDialogOpen(false);
  };

  return (
    <>
      <Chip
        label={
          <Grid container direction='row' wrap='nowrap'>
            <Grid item container alignContent='center'>
              {t('courses.continuity-discount')}
            </Grid>
            {courseContext.course != null && (
              <>
                <Grid item>
                  <IconButton
                    onClick={() => setModDialogOpen(true)}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <EditTwoToneIcon style={{ fontSize: '18px', padding: 0 }} />
                  </IconButton>
                </Grid>

                {hasContinuityDiscount && (
                  <Grid item>
                    <IconButton
                      onClick={() => setDelDialogOpen(true)}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <DeleteRoundedIcon style={{ fontSize: '18px', padding: 0 }} />
                    </IconButton>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        }
        size='small'
        color={hasContinuityDiscount ? 'success' : 'default'}
        icon={hasContinuityDiscount ? <CheckCircleOutlineRoundedIcon /> : <HighlightOffIcon />}
      />
      <DiscountChangeDialog isOpen={isModDialogOpen} needsWarning={hasContinuityDiscount} onClose={handleCloseDialog} />
      <DiscountDeletionDialog isOpen={isDelDialogOpen} onClose={handleCloseDialog} />
    </>
  );
};

export default DiscountStatusChip;
