import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { getDayInUnixTimestamp } from 'utils/date';
import { Badge, Button, InputBase } from '@mui/material';
import EventRoundedIcon from '@mui/icons-material/EventRounded';

const DateFilter = ({ handleFilterChange, id, filterValue, placeholder }) => {
  const [open, setOpen] = useState(false);
  const handleChange = (date) => {
    handleFilterChange(id, getDayInUnixTimestamp(new Date(date)).toString());
  };
  const handleIconClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DatePicker
      id={id}
      size='small'
      value={new Date(filterValue * 1000)}
      onChange={handleChange}
      placeholder={placeholder}
      open={open}
      onClose={handleClose}
      renderInput={({ inputRef, inputProps }) => (
        <Button 
          startIcon={
            <Badge onClick={handleIconClick}>
              <EventRoundedIcon />
            </Badge>
          } 
          color='secondary' 
          variant='text' 
          sx={{ width: '8rem', pl: 1 }} 
          disableRipple
        >
          <InputBase
            ref={inputRef}
            {...inputProps}
            sx={{ fontWeight: 700, color: (theme) => theme.palette.secondary.main, fontSize: '0.875rem' }}
          />
        </Button>
      )}
    />
  );
};

DateFilter.propTypes = {
  id: PropTypes.string,
  filterValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleFilterChange: PropTypes.func
};

export default DateFilter;
