import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const HiddenInput = ({
  name,
  defaultValue
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value } }) => (
        <input type="hidden" name={name} value={value} />
      )}
    />
  );
};

export default HiddenInput;
