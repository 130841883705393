import React, { useEffect, useState } from 'react';
import { Box, Card, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { Button, Dialog, Form, Title } from 'ui';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useViewer from '../Viewer/ViewerContext/useViewer';
import useTranslation from '../../hooks/useTranslation';
import TableFilter from '../../ui/TableFilter';
import Toolbar from 'components/Layout/Toolbar';
import { TableForDesktop } from './Table/table-layout-desktop';
import { TableForMobile } from './Table/table-layout-mobile';

const defaultRowsPerPage = 10;
const rowsPerPageValues = [10, 25, 50, 100];

const TablePage = ({
  title,
  titleVariant,
  titleGutterBottom,
  fields,
  idField = 'id',
  updateIdField,
  newLabel,
  deleteLabel,
  writeRole,
  onGetRows,
  onCreateRow,
  onUpdateRow,
  onDeleteRow,
  onRowClick,
  filterConfig,
  updateMessage,
  onFilterChange,
  refetchTrigger
}) => {
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(
    filterConfig
      ? filterConfig.filter(f => f.isVisible == null ? true : f.isVisible(filterConfig)).reduce(
          (acc, filter) => ({ ...acc, [filter[idField]]: filter.filterInitialValue || filter.filterValue }),
          {}
        )
      : null
  );
  const [rows, setRows] = useState([]);
  const [rowToEdit, setRowToEdit] = useState();
  const { viewerRoles, viewerHasRole } = useViewer();
  const { t } = useTranslation();

  const fetchRows = async () => {
    const response = await onGetRows({ ...filters, page, rowsPerPage });
    setRows(response.items);
    setCount(response.pageInfo.count);
    setLoading(false);
  };

  useEffect(() => {
    if (refetchTrigger) fetchRows();
  }, [refetchTrigger]);

  const handleSubmit = async (formFields) => {
    setFormLoading(true);
    if (rowToEdit) {
      await onUpdateRow(rowToEdit?.[updateIdField || idField], formFields);
    } else {
      await onCreateRow(formFields);
    }
    fetchRows();
    setFormLoading(false);
    setDialogOpen(false);
  };

  const handleNewClick = () => {
    setRowToEdit(null);
    setDialogOpen(true);
  };

  const handleRowClick = (item) => {
    if (parseInt(item.locked)) return;
    if (onRowClick) {
      onRowClick(item);
      return;
    }
    if (onUpdateRow || onDeleteRow) {
      setRowToEdit(item);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    setFormLoading(true);
    await onDeleteRow(rowToEdit?.[idField]);
    fetchRows();
    setFormLoading(false);
    setDialogOpen(false);
  };

  const handleFilterChange = (f) => {
    const newFilter = f.filter(filter => filter.isVisible == null ? true : filter.isVisible(f)).reduce((acc, filter) => ({ ...acc, [filter[idField]]: filter.filterValue }), {});
    setFilters(newFilter);
    setPage(0);
    onFilterChange && onFilterChange({ ...newFilter, page, rowsPerPage });
  };

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchRows();
  }, [filters, page, rowsPerPage]);

  const dialogTitle = rowToEdit ? t('common.update-item') : t('common.create-new-item');

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box>
      {viewerHasRole(writeRole || viewerRoles.ADMIN) && onCreateRow && (
        <Toolbar>
          <Button onClick={handleNewClick} startIcon={<AddRoundedIcon />} variant='text' size='small'>
            {newLabel || t('common.create-new-item')}
          </Button>
        </Toolbar>
      )}
      {title && <Title variant={titleVariant} title={title} gutterBottom={titleGutterBottom} />}

      {filterConfig && <TableFilter initialFilters={filterConfig} onFilterChange={handleFilterChange} />}

      {loading ? (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : rows.length < 1 ? (
        <Card sx={{ mt: 2, p: 2, borderRadius: 2 }}>
          <Typography sx={{ textAlign: 'center' }} variant='body1'>
            {t('table.no-rows')}
          </Typography>
        </Card>
      ): isMobile ? (
        <TableForMobile
          fields={fields}
          rows={rows}
          idField={idField}
          onUpdateRow={onUpdateRow}
          onDeleteRow={onDeleteRow}
          onRowClick={onRowClick}
          handleRowClick={handleRowClick}
          rowsPerPageValues={rowsPerPageValues}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <TableForDesktop
          fields={fields}
          rows={rows}
          idField={idField}
          onUpdateRow={onUpdateRow}
          onDeleteRow={onDeleteRow}
          onRowClick={onRowClick}
          handleRowClick={handleRowClick}
          rowsPerPageValues={rowsPerPageValues}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <Dialog title={dialogTitle} isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} hideActions>
        <Form
          fields={fields}
          onSubmit={onUpdateRow || onCreateRow ? handleSubmit : null}
          onAltAction={handleCloseDialog}
          onExtraAction={onDeleteRow ? handleDelete : null}
          extraActionLabel={rowToEdit ? deleteLabel || 'Törlés' : null}
          submitLabel={dialogTitle}
          loading={isFormLoading}
          defaultValues={rowToEdit}
          message={updateMessage}
        />
      </Dialog>
    </Box>
  );
};

export default TablePage;
