import { Box, FormGroup, Stack, SwipeableDrawer, Typography } from '@mui/material';

import CheckboxFilter from './CheckboxFilter';
import InputFilter from './InputFilter';
import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';
import useTranslation from 'hooks/useTranslation';
import { CloseRounded } from '@mui/icons-material';
import { FilterType } from './TableFilter';
import { useState } from 'react';
import Button from 'ui/Button/Button';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';

export const FilterForMobile = ({ selectedFilters, handleFiltersChange, handleFilterChange, handleFilterClear }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  const clearFilter = () => {
    handleFilterClear();
    toggleDrawer();
  };

  const hasActiveFilter = selectedFilters.some((filter) => filter.inactiveValue !== filter.filterValue);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <Button
        onClick={toggleDrawer}
        variant='text'
        endIcon={hasActiveFilter ? <FilterAltRoundedIcon /> : <FilterAltOutlinedIcon color='secondary' />}
      >
        <Typography sx={{ color: hasActiveFilter ? 'primary' : 'text.secondary' }}>{t('common.filter')}</Typography>
      </Button>
      <SwipeableDrawer
        anchor={'bottom'}
        open={drawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        PaperProps={{ sx: { borderTopLeftRadius: '32px', borderTopRightRadius: '32px' } }}
      >
        <FormGroup>
          <Stack direction='column' spacing={1} sx={{ py: '32px', px: '32px', minHeight: '50vh' }}>
            {selectedFilters.map(({ filterValue, filterType, filterOptions, label, id, icon }) => {
              if (filterType === FilterType.MultiSelect && filterOptions && Array.isArray(filterValue)) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterOptions={filterOptions}
                    filterValue={filterValue}
                    handleMultiSelectChange={handleFiltersChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Select && filterOptions) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterValue={filterValue}
                    filterOptions={filterOptions}
                    handleMultiSelectChange={handleFilterChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Checkbox) {
                return (
                  <CheckboxFilter
                    id={id}
                    key={id}
                    label={label}
                    checked={filterValue}
                    handleCheckboxChange={handleFilterChange}
                  />
                );
              }

              if (filterType === FilterType.Date) {
                return (
                  <DateFilter
                    id={id}
                    key={id}
                    label={label}
                    filterValue={filterValue}
                    handleFilterChange={handleFilterChange}
                  />
                );
              }

              if (filterType === FilterType.Search) {
                return (
                  <InputFilter
                    key={id}
                    id={id}
                    placeholder={label}
                    filterValue={filterValue}
                    handleFilterChange={handleFilterChange}
                  />
                );
              }

              return null;
            })}
            <Box sx={{ flexGrow: 1 }} />
            <Button variant='text' onClick={clearFilter} endIcon={<CloseRounded />} color='secondary'>
              <Typography>{t('common.clear-filter')}</Typography>
            </Button>
          </Stack>
        </FormGroup>
      </SwipeableDrawer>
    </>
  );
};
