import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardActions, CardContent, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Button } from '../../ui';
import useTranslation from '../../hooks/useTranslation';
import barionImg from 'images/barion.png';
import useViewer from '../Viewer/ViewerContext/useViewer';
import PriceDisplay from './PriceDisplay';

const SubscribeStudent = ({ subscriptionTypes }) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(subscriptionTypes[0] || null);

  const { viewerRoles, viewerHasRole } = useViewer();

  const { t } = useTranslation();

  const handleOpenCart = () => {
    navigate({ pathname: `/cart/${subscription.uid}` });
  };

  const handleSetSubscription = (subscriptionId) => {
    setSubscription(subscriptionTypes.find((item) => item.uid === subscriptionId));
  };

  return (
    <>
      <CardContent>
        <Typography variant='body2' sx={{ mb: 1 }}>
          {t('courses.subscription-info')}
        </Typography>
        {subscription && (
          <>
            <ToggleButtonGroup
              value={subscription?.uid}
              exclusive
              onChange={(e) => handleSetSubscription(e.target.value)}
              aria-label='text alignment'
              fullWidth
            >
              {subscriptionTypes.map((item) => (
                <ToggleButton key={item.uid} value={item.uid} aria-label='left aligned'>
                  {item.hours} {t('courses.hour')}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <PriceDisplay subscription={subscription} />
          </>
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between', mt: -2 }}>
        <img src={barionImg} alt='barion' width='200' />
        <Button onClick={handleOpenCart} disabled={viewerHasRole(viewerRoles.TEACHER)}>
          {t('courses.subscribe')}
        </Button>
      </CardActions>
    </>
  );
};

export default SubscribeStudent;
