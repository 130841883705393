import useOptions from 'components/Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { getDayInUnixTimestamp } from 'utils/date';
import moment from 'moment';

const useTodoFilters = () => {
  const { courses, students } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'noteType',
      filterInitialValue: 'todo',
    },
    {
      id: 'course',
      label: 'Tanfolyam',
      filterInitialValue: searchParams.get('course') || '',
      filterValue: '',
      filterOptions: courses,
      filterType: 'select',
      icon: BookOutlinedIcon
    },
    {
      id: 'student',
      label: 'Tanuló',
      filterInitialValue: searchParams.get('student') || '',
      filterValue: '',
      filterOptions: students,
      filterType: 'select',
      icon: PeopleOutlineOutlinedIcon
    },
    {
      id: 'content',
      label: 'Tartalom',
      filterInitialValue: searchParams.get('content') || '',
      filterValue: '',
      filterType: 'search'
    },
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: [
        { key: 'done', value: 'Kész' },
        { key: 'actual', value: 'Aktuális' },
        { key: 'expired', value: 'Lejárt' },
        { key: 'planned', value: 'Tervezett' },
      ],
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    },
    {
      id: 'ddlFrom',
      label: 'Határidő',
      filterInitialValue: searchParams.get('ddlFrom') ?? getDayInUnixTimestamp(moment().subtract(1, 'months').toDate()).toString(),
      filterValue: '',
      filterType: 'date',
    },
    {
      id: 'ddlTo',
      label: 'Határidő',
      filterInitialValue: searchParams.get('ddlTo') ?? getDayInUnixTimestamp(new Date()).toString(),
      filterValue: '',
      filterType: 'date',
    }
  ];
};

export default useTodoFilters;
