import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useNotesApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getNotes = async (filters) => {
    try {
      const { data } = await api({
        url: `/notes${createFilterParams(filters)}`,
        method: 'get'
      });
      data.items = data.items.map(item => ({
        ...item,
        locked: item.deadline != null ? item.isDone : undefined 
      }))
      return data; 
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createNote = async (note) => {
    try {
      const { data } = await api({
        url: '/notes',
        data: note,
        method: 'post'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateNote = async (id, note) => {
    try {
      const { data } = await api({
        url: `/notes/${id}`,
        data: note,
        method: 'put'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const completeNote = async (id, last_modified_on) => {
    try {
      const { data } = await api({
        url: `/notes/${id}/complete`,
        data: { last_modified_on },
        method: 'put'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const toggleNoteImportance = async (id, last_modified_on) => {
    try {
      const { data } = await api({
        url: `/notes/${id}/toggleImportance`,
        data: { last_modified_on },
        method: 'put'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const deleteNote = async (id) => {
    try {
      const { data } = await api({
        url: `/notes/${id}`,
        method: 'delete'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getNotes,
    createNote,
    updateNote,
    completeNote,
    toggleNoteImportance,
    deleteNote
  };
};

export default useNotesApi;
