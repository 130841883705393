import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useStudentApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getStudents = async (filters) => {
    try {
      const { data } = await api({
        url: `/student${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getStudent = async (id) => {
    try {
      const { data } = await api({
        url: `/student/${id}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getStudentProfile = async (id) => {
    try {
      const { data } = await api({
        url: `/student/${id}/profile`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateStudentProfile = async (id, profile) => {
    try {
      const { data } = await api({
        url: `/student/${id}/profile`,
        method: 'put',
        data: profile
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getStudentCourses = async (id) => {
    try {
      const { data } = await api({
        url: `/student/${id}/courses`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const getSubscriptionTypesForStudentAndCourse = async (studentId, courseId) => {
    try {
      const { data } = await api({
        url: `/student/${studentId}/courses/${courseId}/subscription-types`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getStudents,
    getStudent,
    getStudentProfile,
    updateStudentProfile,
    getStudentCourses,
    getSubscriptionTypesForStudentAndCourse
  };
};

export default useStudentApi;
