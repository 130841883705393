import useTranslation from 'hooks/useTranslation';
import StatusChip from '../Common/StatusChip';
import useOptions from '../Options/OptionsContext/useOptions';
import * as yup from 'yup';
import { DATE_FORMATS, formatDate } from 'utils/date';
import { CompleteTodoButton } from './CompleteTodoButton';
import { ToggleNoteImportanceIcon } from './ToggleNoteImportanceIcon';
import useViewer from 'components/Viewer/ViewerContext/useViewer';


const useNoteFields = (setRefetchTrigger) => {
  const { courses } = useOptions();
  const { t } = useTranslation();
  const { viewerHasRole, viewerRoles } = useViewer();
  return [
    {
      name: 'student',
      hiddenFormField: true
    },
    {
      name: 'content',
      cellRenderer: (row) => row.content,
      showInTable: true,
      type: 'textarea',
      fieldProps: {
        label: 'Tartalom',
        rows: 5,
        maxRows: 10
      },
      rule: yup.string().trim().required('Tartalom megadása kötelező')
    },
    {
      name: 'course',
      cellRenderer: (row) => row.course?.value ?? '-',
      showInTable: true,
      type: 'select',
      fieldProps: {
        label: 'Kapcsolódó tanfolyam',
        options: [...courses, {key: '', value: 'Nincs kapcsolódó kurzus'}],
      },
      rule: yup.string().nullable()
    },
    {
      name: 'deadline',
      type: 'date',
      cellRenderer: (row) => row.deadline != null ? formatDate(row.deadline, DATE_FORMATS.shortDate) : '-',
      showInTable: true,
      fieldProps: {
        label: 'Határidő',
        helperText: t('helpertext.note-deadline')
      },
      rule: yup.string().nullable()
    },
    {
      name: 'status',
      cellRenderer: (row) => row.status != null ? <StatusChip entity={'note'} status={row.status} /> : '-',
      showInTable: true,
      fieldProps: {
        label: 'Státusz',
      },
    },
    ...(viewerHasRole(viewerRoles.OPERATOR)) ? [{
      name: '',
      cellRenderer: (row) => row.status != null && row.isDone === '0' ? <CompleteTodoButton noteId={row.id} lastModification={row.last_modified_on} setRefetchTrigger={setRefetchTrigger} /> : '',
      showInTable: true,
      fieldProps: {
        label: '',
      },
    }]: [],
    {
      name: 'last_modified_by',
      cellRenderer: (row) => row.last_modified_by != null ? row.last_modified_by : 'Rendszer',
      showInTable: true,
      fieldProps: {
        label: 'Utolsó módosító',
      },
    },
    ...(viewerHasRole(viewerRoles.OPERATOR)) ? [{
      name: 'important',
      cellRenderer: (row) => <ToggleNoteImportanceIcon noteId={row.id} important={row.important} lastModification={row.last_modified_on} setRefetchTrigger={setRefetchTrigger} />,
      showInTable: true,
      type: 'checkbox',
      fieldProps: {
        label: '',
        checkBoxLabel: 'Csillagozott'
      },
    }]: [],
    {
      name: 'last_modified_on',
      hiddenFormField: true
    },
  ];
};

export default useNoteFields;
