import { Box } from '@mui/material';
import TablePage from '../../components/Common/TablePage';
import useTransactionsFields from 'components/Transactions/useTransactionsFields';
import useTransactionApi from 'components/Transactions/useTransactionApi';
import useSubscriptionApi from 'components/Subscription/useSubscriptionApi';
import { useCourseContext } from './CourseContext';
import useTransactionsFilters from 'components/Transactions/useTransactionFilters';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { useState } from 'react';

const CourseTransactionList = () => {
  const [refetchTrigger, setRefetchTrigger] = useState(0)
  const { course } = useCourseContext();
  const { getTransactions } = useTransactionApi({ courseId: course.uid });
  const { updateSubscription } = useSubscriptionApi();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const filterConfig = !viewerRoleIs(viewerRoles.STUDENT) ? useTransactionsFilters() : null;
  const fields = useTransactionsFields({ hideCourse: true, hideStudent: viewerRoleIs(viewerRoles.STUDENT), setRefetchTrigger });

  return (
    <Box sx={{ mt: 4 }}>
      <TablePage
        fields={fields}
        updateIdField='subscriptionId'
        onGetRows={getTransactions}
        onUpdateRow={viewerRoleIs(viewerRoles.ADMIN) ? updateSubscription : null}
        filterConfig={filterConfig}
        refetchTrigger={refetchTrigger}
      />
    </Box>
  );
};

export default CourseTransactionList;
