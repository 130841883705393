import React, { useEffect, useState } from 'react';
import useSubscriptionApi from './useSubscriptionApi';

import SubscribeStudent from './SubscribeStudent';
import { Card, CardHeader, IconButton } from '@mui/material';
import SubscriptionListAdmin from './SubscriptionListAdmin';
import useTranslation from '../../hooks/useTranslation';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useViewer from '../Viewer/ViewerContext/useViewer';
import { CloseRounded } from '@mui/icons-material';
import { useCourseContext } from 'components/Course/CourseContext';
import useStudentApi from 'components/Student/useStudentApi';

const Subscriptions = () => {
  const { getSubscriptionTypesForStudentAndCourse } = useStudentApi();
  const { getSubscriptionTypes } = useSubscriptionApi();
  const [loading, setLoading] = useState(true);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [extend, setExtend] = useState(false);
  const { viewer, viewerRoles, viewerHasRole, viewerRoleIs } = useViewer();
  const { course } = useCourseContext();

  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);

  const fetchSubscriptions = async () => {
    let typesResult;
    if (viewerRoleIs(viewerRoles.STUDENT)) {
      typesResult = await getSubscriptionTypesForStudentAndCourse(viewer.id, course.uid);
    } else {
      typesResult = await getSubscriptionTypes(course.uid);
    }
    setSubscriptionTypes(typesResult);
    setLoading(false);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <Card>
      <CardHeader
        title={t('courses.subscription-buy')}
        action={
          viewerHasRole(viewerRoles.ADMIN) ? (
            <IconButton color='primary' onClick={() => setEditMode(!isEditMode)}>
              {isEditMode ? <ArrowBackIcon fontSize='small' /> : <EditTwoToneIcon fontSize='small' />}
            </IconButton>
          ) : extend ? (
            <IconButton onClick={() => setExtend(false)}>
              <CloseRounded />
            </IconButton>
          ) : null
        }
      />
      {!loading && (
        <>
          {!isEditMode && <SubscribeStudent subscriptionTypes={subscriptionTypes} />}
          {isEditMode && (
            <SubscriptionListAdmin
              courseId={course.uid}
              subscriptionTypes={subscriptionTypes}
              refetch={fetchSubscriptions}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default Subscriptions;
