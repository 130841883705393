import useApi from '../../api/useApi';
import { useError } from '../../hooks/useError';

const useCouponsApi = () => {
  const { api, createFilterParams } = useApi();
  const { enqueueErrorSnackbar } = useError();

  const getCoupons = async (filters) => {
    try {
      const { data } = await api({
        url: `/coupons${createFilterParams(filters)}`,
        method: 'get'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const createCoupon = async (coupon) => {
    try {
      const { data } = await api({
        url: '/coupons',
        data: coupon,
        method: 'post'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  const updateCoupon = async (id, coupon) => {
    try {
      const { data } = await api({
        url: `/coupons/${id}`,
        data: coupon,
        method: 'put'
      });
      return data;
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return {
    getCoupons,
    createCoupon,
    updateCoupon
  };
};

export default useCouponsApi;
