import { Card as MuiCard, CardActions, CardContent, Typography, styled, Box, CardMedia } from '@mui/material';
import { Button } from 'ui';
import { Link } from 'react-router-dom';

import en from 'images/flags/en.svg';
import hu from 'images/flags/hu.svg';
import de from 'images/flags/de.svg';
import ko from 'images/flags/ko.svg';
import fr from 'images/flags/fr.svg';
import ru from 'images/flags/ru.svg';
import ar from 'images/flags/ar.png';
import useOptions from 'components/Options/OptionsContext/useOptions';
import useTranslation from 'hooks/useTranslation';
import CourseMeta from './CourseMeta';
import theme from '../../theme/theme';

export const defaultImages = {
  en,
  hu,
  de,
  ko,
  fr,
  ru,
  ar,
  degfaultImage: en
};

const getCourseImage = (languageCode) => {
  return defaultImages[languageCode.toLowerCase()] || defaultImages.defaultImage;
};

const StyledCard = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.divider}`,
  textDecoration: 'none',
  width: 360
}));

const CourseCard = ({ item, itemLink, ...rest }) => {
  const { t } = useTranslation();
  const { getOption } = useOptions();

  const image = getCourseImage(getOption('languages', item.language).code);

  return (
    <StyledCard component={Link} to={itemLink} {...rest}>
      <CardMedia
        component='img'
        alt='Hiányzó kép'
        sx={{
          border: 'none',
          aspectRatio: '2/1',
          color: 'rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        image={image}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <Typography variant='h5' component='div' gutterBottom>
            {item.name}
          </Typography>
          <CourseMeta course={item} />
          <Typography
            variant='body2'
            sx={{
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              wordBreak: 'break-word',
              display: '-webkit-box',
              color: theme.palette.text.secondary,
              mt: 2
            }}
          >
            {item.description}
          </Typography>
        </Box>
      </CardContent>
      {itemLink && (
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button component={Link} size='small' to={itemLink} variant='contained'>
            {t('courses.read-more')}
          </Button>
        </CardActions>
      )}
    </StyledCard>
  );
};

export default CourseCard;
