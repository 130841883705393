import { useEffect, useState } from 'react';
import StudentCourses from 'components/Student/StudentCourses';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useDashboardApi from 'components/Dashboard/useDashboardApi';
import Grid from '@mui/material/Unstable_Grid2';

import UpcomingEvents from 'components/Event/UpcomingEvents';
import TodoTable from 'components/Notes/TodoTable';
import { CircularProgress, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';

const DashboardPage = () => {
  const { viewer, viewerRoleIs, viewerRoles, viewerHasRole } = useViewer();
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState(null);
  const { getDashboard } = useDashboardApi();
  const { t } = useTranslation();

  const fetchDashboard = async () => {
    const response = await getDashboard();
    setDashboard(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <Grid container spacing={2}>
      {viewerHasRole(viewerRoles.OPERATOR) && (
        <>
          <Grid xs={12} sx={{ mt: 2 }}>
            <Typography variant='h2'>{t('dashboard.todo-title')}</Typography>
          </Grid>
          <Grid xs={12}>
            <TodoTable />
          </Grid>
        </>
      )}
      {(viewerRoleIs(viewerRoles.STUDENT) || viewerRoleIs(viewerRoles.TEACHER)) && dashboard?.events?.length > 0 && (
        <Grid xs={12}>
          {loading ? (
            <Grid xs={12} sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <UpcomingEvents events={dashboard.events} />
          )}
        </Grid>
      )}
      {viewerRoleIs(viewerRoles.STUDENT) && (
        <Grid xs={12}>
          <StudentCourses studentId={viewer.id} />
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardPage;
