import * as yup from 'yup';
import StatusChip from '../Common/StatusChip';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { formatDate, DATE_FORMATS } from 'utils/date';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import { Box } from '@mui/system';
import { Link } from 'ui';
import useOptions from 'components/Options/OptionsContext/useOptions';

const useEntryFields = () => {
  const { viewerHasRole, viewerRoles } = useViewer();
  const { entryStatuses, courseLocationTypes } = useOptions();

  return [
    ...(viewerHasRole(viewerRoles.TEACHER)
      ? [
          {
            name: 'user',
            cellRenderer: (row) => (
              <Link underline='hover' sx={{ fontWeight: 700 }} to={`/students/${row.userId}`}>{`${
                row.firstName || ''
              } ${row.lastName || ''}`}</Link>
            ),
            showInTable: true,
            fieldProps: {
              label: 'Felhasználó'
            }
          },
          {
            name: 'start',
            type: 'datetime',
            fieldProps: {
              label: 'Kezdés'
            },
            rule: yup.number()
          },
          {
            name: 'end',
            type: 'datetime',
            fieldProps: {
              label: 'Befejezés'
            },
            rule: yup.number()
          }
        ]
      : []),
    {
      name: 'date',
      cellRenderer: (row) => formatDate(row.start, DATE_FORMATS.shortDate),
      showInTable: true,
      fieldProps: {
        label: 'Dátum'
      }
    },
    {
      name: 'time',
      cellRenderer: (row) => `${formatDate(row.start, DATE_FORMATS.time)} - ${formatDate(row.end, DATE_FORMATS.time)}`,
      showInTable: true,
      fieldProps: {
        label: 'Időpont'
      }
    },
    {
      name: 'location',
      cellRenderer: (row) => courseLocationTypes.find((type) => type.key === row.location_type)?.value || '',
      showInTable: true,
      fieldProps: {
        label: 'Helyszín'
      }
    },
    {
      name: 'status',
      cellRenderer: (row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <StatusChip status={row.status} entity='entry' />
          {row.locked && <LockRoundedIcon fontSize='small' color='inherit' sx={{ opacity: 0.5 }} />}
        </Box>
      ),
      type: viewerHasRole(viewerRoles.TEACHER) ? 'select' : null,
      showInTable: true,
      fieldProps: {
        label: 'Státusz',
        options: entryStatuses
      }
    }
  ];
};

export default useEntryFields;
