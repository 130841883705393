import TablePage from '../../components/Common/TablePage';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useCouponsApi from 'components/Coupon/useCouponApi';
import useCouponFields from 'components/Coupon/useCouponFields';
import useTranslation from 'hooks/useTranslation';
import useCouponFilters from 'components/Coupon/useCouponFilters';

const CouponsPage = () => {
  const { getCoupons, createCoupon, updateCoupon } = useCouponsApi();
  const fields = useCouponFields();
  const { viewerRoles, viewerHasRole } = useViewer();
  const filtersConfig = useCouponFilters();
  const { t } = useTranslation();

  return (
    <TablePage
      fields={fields}
      onGetRows={getCoupons}
      newLabel={t('coupons.create-coupon')}
      onCreateRow={createCoupon}
      onUpdateRow={viewerHasRole(viewerRoles.ADMIN) ? updateCoupon : null}
      filterConfig={filtersConfig}
    />
  );
};

export default CouponsPage;
