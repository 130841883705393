import { useState } from 'react';
import { Stack, Hidden, Typography, Checkbox, FormControlLabel, TextField, FormHelperText } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog } from 'ui';
import Select from 'ui/Inputs/Select';
import useOptions from 'components/Options/OptionsContext/useOptions';
import useTransactionApi from 'components/Transactions/useTransactionApi';
import useStudentApi from 'components/Student/useStudentApi';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from 'ui/Form/controls/FormError';

const NewSubscriptionButton = ({ studentId, setRefetchTrigger }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const { t } = useTranslation();
  const { viewerRoleIs, viewerRoles } = useViewer();
  const { courses, transactionTypes } = useOptions();
  const { getSubscriptionTypesForStudentAndCourse } = useStudentApi();
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);
  const { createTransaction } = useTransactionApi({});

  const schema = yup.object().shape({
    course: yup.string().required('Tanfolyam megadása kötelező'),
    subscriptionType: yup.string().required('Típus megadása kötelező'),
    hours: yup.number().required('Óraszám megadása kötelező').min(0, 'Az órák száma nem lehet negatív'),
    transactionType: yup.string().required('Fizetés módjának megadása kötelező'),
    createInvoice: yup.boolean(),
    paymentAccepted: yup.boolean()
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      course: '',
      subscriptionType: '',
      hours: '',
      transactionType: 'Cash',
      createInvoice: true,
      paymentAccepted: false
    }
  });

  const handleCourseChange = async (courseId) => {
    const sTypes = await getSubscriptionTypesForStudentAndCourse(studentId, courseId);
    setSubscriptionTypes(
      sTypes.map((s) => ({ key: s.uid, value: `${s.hours} órás`, price: s.discountedPrice, hours: s.hours }))
    );
    setValue('subscriptionType', '');
  };

  const handleSubscriptionTypeChange = (subscriptionTypeKey) => {
    const t = subscriptionTypes.find((s) => s.key === subscriptionTypeKey);
    setValue('hours', t.hours);
    setSelectedSubscriptionType(t);
  };

  const handleTransactionTypeChange = (transactionType) => {
    setValue('transactionType', transactionType);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSubscriptionTypes([]);
    setSelectedSubscriptionType(null);
    setValue('course', '');
    setValue('subscriptionType', '');
    setValue('hours', '');
    setValue('createInvoice', true);
    setValue('paymentAccepted', false);
  };

  const onSubmit = async (data) => {
    setFormLoading(true);
    await createTransaction({
      subscriptionTypeId: selectedSubscriptionType.key,
      studentId,
      transactionType: data.transactionType,
      createInvoice: data.createInvoice,
      hours: data.hours
    });
    setRefetchTrigger((prev) => prev + 1);
    handleCloseDialog();
    setFormLoading(false);
  };

  const isSubmitDisabled =
    selectedSubscriptionType?.price == null ||
    (watch('transactionType') === 'Cash' && !watch('paymentAccepted')) ||
    isFormLoading;

  const isCreateInvoiceDisabled = watch('transactionType') === 'Transfer';

  if (!viewerRoleIs(viewerRoles.ADMIN)) {
    return null;
  }

  return (
    <>
      <Button startIcon={<AddRoundedIcon />} variant='contained' onClick={() => setDialogOpen(true)}>
        <Hidden smDown>{t('courses.subscription-new')}</Hidden>
      </Button>

      <Dialog title={t('courses.subscription-new')} isOpen={isDialogOpen} onClose={handleCloseDialog} hideActions>
        <Typography variant='body1'>
          Új bérlet rögzítéséhez válasszd ki a tanfolyamot, a bérlet típusát és a fizetési módot.
        </Typography>
        <FormProvider>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='column' gap={3} sx={{ mt: 4 }}>
              <Controller
                name='course'
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <FormError error={error}>
                    <Select
                      label='Tanfolyam'
                      options={courses}
                      fullWidth
                      ref={ref}
                      onBlur={onBlur}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleCourseChange(e.target.value);
                      }}
                      error={!!error}
                    />
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormError>
                )}
              />

              <Controller
                name='subscriptionType'
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <FormError error={error}>
                    <Select
                      label='Típus'
                      options={subscriptionTypes}
                      fullWidth
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      onChange={(e) => {
                        onChange(e);
                        handleSubscriptionTypeChange(e.target.value);
                      }}
                      disabled={subscriptionTypes.length === 0}
                      error={!!error}
                    />
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormError>
                )}
              />

              <Controller
                name='hours'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value ?? ''}
                    label='Óraszám'
                    type='number'
                    fullWidth
                    error={!!errors.hours}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                    helperText={errors.hours?.message ?? undefined}
                  />
                )}
              />

              <Controller
                name='transactionType'
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <FormError error={error}>
                    <Select
                      label='Fizetés módja'
                      options={transactionTypes.filter((t) => t.key !== 'Barion')}
                      fullWidth
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      onChange={(e) => {
                        onChange(e);
                        handleTransactionTypeChange(e.target.value);
                      }}
                      error={!!error}
                    />
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormError>
                )}
              />

              <Controller
                name='createInvoice'
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={<Checkbox {...field} disabled={isCreateInvoiceDisabled} checked={isCreateInvoiceDisabled ? false : field.value} />} label='Számla kiállítása' />
                )}
              />
            </Stack>

            <Stack alignItems='center' sx={{ py: 4 }}>
              <Typography variant='body2' color='text.secondary'>
                Fizetendő:
              </Typography>
              <Typography variant='h4'>
                {selectedSubscriptionType?.price != null
                  ? `${parseInt(selectedSubscriptionType.price).toLocaleString('hu')} Ft`
                  : 'Válassz bérletet'}
              </Typography>
            </Stack>

            <Stack alignItems='center'>
              {watch('transactionType') === 'Cash' && (
                <Controller
                  name='paymentAccepted'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label='A fizetendő összeget átvettem'
                    />
                  )}
                />
              )}
              <Stack direction='row' alignItems='center' gap={2}>
                <Button variant='contained' color='primary' disabled={isSubmitDisabled} type='submit'>
                  Bérlet rögzítése
                </Button>
                <Typography variant='body'>vagy</Typography>
                <Button variant='text' color='primary' onClick={handleCloseDialog}>
                  Mégse
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default NewSubscriptionButton;
