import { Button, Dialog } from 'ui';
import { Stack } from '@mui/material';
import ContinuityDiscountForm from './ContinuityDiscountForm';
import { useEffect, useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import { useCourseContext } from './CourseContext';

const DiscountChangeDialog = ({ isOpen, onClose, needsWarning }) => {
  const [warningConfirmed, setWarningConfirmed] = useState(false);

  const { t } = useTranslation();
  const { course, refetchCourse } = useCourseContext();

  useEffect(() => {
    if (isOpen && needsWarning) {
      setWarningConfirmed(false);
    }
  }, [needsWarning, isOpen]);

  const handleSubmit = () => {
    refetchCourse();
    onClose();
  };

  return (
    <>
      <Dialog
        title={needsWarning ? t('courses.update-continuity-discount') : t('courses.add-continuity-discount')}
        isOpen={isOpen}
        onClose={onClose}
        hideActions
      >
        {needsWarning && !warningConfirmed ? (
          <>
            {t('courses.update-continuity-discount-warning')}
            <Stack spacing={2} direction='row' justifyContent={'flex-end'} sx={{ flexGrow: 1 }}>
              <Button color='secondary' variant='text' onClick={onClose}>
                {t('common.cancel')}
              </Button>
              <Button onClick={() => setWarningConfirmed(true)}>{t('common.update-item')}</Button>
            </Stack>
          </>
        ) : (
          <ContinuityDiscountForm onCancel={onClose} onSave={handleSubmit} defaultValues={course} />
        )}
      </Dialog>
    </>
  );
};

export default DiscountChangeDialog;
