import { useState } from 'react';

import { Form } from 'ui';

import useCourseApi from './useCourseApi';
import useContinuityDiscountFormFields from './useContinuityDiscountFormFields';

const ContinuityDiscountForm = ({ onSave, onCancel, defaultValues }) => {
  const [loading, setLoading] = useState(false);
  const { updateCourseContinuityDiscount } = useCourseApi();
  const formFields = useContinuityDiscountFormFields();
  const handleSubmit = async (fields) => {
    setLoading(true);
    await updateCourseContinuityDiscount(defaultValues.uid, fields);
    setLoading(false);

    onSave();
  };

  return (
    <Form
      fields={formFields}
      onSubmit={handleSubmit}
      onAltAction={onCancel}
      loading={loading}
      defaultValues={defaultValues}
      sx={{ mt: 2 }}
    />
  );
};

export default ContinuityDiscountForm;
