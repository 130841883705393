import { useSearchParams } from 'react-router-dom';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import useOptions from 'components/Options/OptionsContext/useOptions';

const useStudentFilters = () => {
  const { userStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'status',
      label: 'Státusz',
      filterInitalValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: userStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useStudentFilters;
