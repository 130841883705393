import * as yup from 'yup';
import useOptions from 'components/Options/OptionsContext/useOptions';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useTranslation from 'hooks/useTranslation';

export const useStudentInvoiceFieldsPerson = () => {
  return [
    {
      name: 'isCompany',
      cellRenderer: (row) => (row.isCompany === '1' ? 'Cég' : 'Magánszemély'),
      showInTable: true,
      fieldProps: {
        label: 'Típus'
      }
    },
    {
      name: 'invoiceName',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Számlázási név'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCountry',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Ország'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCity',
      showInTable: true,
      type: 'text',
      fieldProps: {
        label: 'Város'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceAddress',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cím'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceZip',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Irányítószám'
      },
      rule: yup.string().required()
    }
  ];
};

export const useStudentInvoiceFieldsCompany = () => {
  const { t } = useTranslation();
  return [
    {
      name: 'isCompany',
      cellRenderer: (row) => (row.isCompany === '1' ? 'Cég' : 'Magánszemély'),
      showInTable: true,
      fieldProps: {
        label: 'Típus'
      }
    },
    {
      name: 'invoiceName',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cégnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCountry',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Ország'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceCity',
      showInTable: true,
      type: 'text',
      fieldProps: {
        label: 'Város'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceAddress',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Cím'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceZip',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Irányítószám'
      },
      rule: yup.string().required()
    },
    {
      name: 'invoiceTaxNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Adószám',
        helperText: t('helpertext.invoiceTaxNumber')
      },
      rule: yup
        .string()
        .test('tax-number', 'Az adószám megfelelő formátumú megadása szükséges: xxxxxxxx-y-zz.', (value) => {
          const pattern = /^\d{8}-\d-\d{2}$/gm;
          return pattern.test(value);
        })
    },
    {
      name: 'invoiceEUTaxNumber',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'EU Adószám',
        helperText: t('helpertext.invoiceEUTaxNumber')
      },
      rule: yup
        .string()
        .test(
          'eu-tax-number',
          'Az EU adószám megfelelő adott országnak megfelelő formátumú megadása szükséges.',
          (value) => {
            if (value === '' || value == null) return true;
            const pattern =
              /^((AT)(U\d{8})|(BE)(0\d{9})|(BG)(\d{9,10})|(CY)(\d{8}[LX])|(CZ)(\d{8,10})|(DE)(\d{9})|(DK)(\d{8})|(EE)(\d{9})|(EL|GR)(\d{9})|(ES)([\dA-Z]\d{7}[\dA-Z])|(FI)(\d{8})|(FR)([\dA-Z]{2}\d{9})|(HU)(\d{8})|(IE)(\d{7}[A-Z]{2})|(IT)(\d{11})|(LT)(\d{9}|\d{12})|(LU)(\d{8})|(LV)(\d{11})|(MT)(\d{8})|(NL)(\d{9}(B\d{2}|BO2))|(PL)(\d{10})|(PT)(\d{9})|(RO)(\d{2,10})|(SE)(\d{12})|(SI)(\d{8})|(SK)(\d{10}))$/gm;
            return pattern.test(value);
          }
        )
    }
  ];
};

export const useUserDetailsFields = (isNaturalPerson) => {
  const { paymentProfiles } = useOptions();
  const { viewerHasRole, viewerRoles } = useViewer();
  const { t } = useTranslation();

  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'lastName',
      type: 'text',
      fieldProps: {
        label: 'Vezetéknév'
      },
      rule: yup.string().required()
    },
    {
      name: 'firstName',
      type: 'text',
      fieldProps: {
        label: 'Keresztnév'
      },
      rule: yup.string().required()
    },
    {
      name: 'email',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      },
      rule: yup.string().email().required()
    },
    {
      name: 'phone',
      type: 'text',
      showInTable: true,
      fieldProps: {
        label: 'Telefon',
        helperText: t('helpertext.phoneNumber')
      },
      rule: yup
        .string()
        .test('is-valid-phoneNumber', 'A telefonszám nem megfelelő formátumú.', (value) => {
          if (value == null || value == '') return true;
          const regexp = /^\+(\d{1,3})( \d+){1,4}$/gm;
          if (!regexp.test(value)) return false;
          const digitsOnly = value.replace(/\D/g, '');
          return digitsOnly.length <= 15;
        })
    },
    ...(viewerHasRole(viewerRoles.ADMIN)
      ? [
          {
            name: 'paymentProfile',
            type: 'select',
            showInTable: true,
            cellRenderer: (row) => paymentProfiles.find((item) => item.key === row.paymentProfile)?.value || '',
            fieldProps: {
              label: 'Számlázási információ',
              options: paymentProfiles.filter((p) => (p.naturalPersonsOnly === '1' ? isNaturalPerson : true))
            },
            rule: yup.string()
          }
        ]
      : [])
  ];
};
