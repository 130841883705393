import { Box, Typography } from '@mui/material';
import theme from 'theme/theme';

export const CaptionValue = ({ caption, value }) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'baseline' }}>
      <Box sx={{ flex: 1, mr: `${theme.spacing(1)}px !important` }}>
        <Typography variant='caption'>{caption}</Typography>
      </Box>
      <Box sx={{ ml: theme.spacing(1), textAlign: 'right' }}>
        <Typography component={'span'} variant='body2'>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
