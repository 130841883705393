import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';

import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const useSubscriptionFilters = (studentId) => {
  const { courses, transactionStatuses, subscriptionStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'course',
      label: 'Tanfolyam',
      filterInitialValue: searchParams.get('course') || '',
      filterValue: '',
      filterOptions: courses,
      filterType: 'select',
      icon: BookOutlinedIcon
    },
    {
      id: 'status',
      label: 'Tr. státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: transactionStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    },
    {
      id: 'subscriptionStatus',
      label: 'Bérlet státusz',
      filterInitialValue: searchParams.get('subscriptionStatus') || '',
      filterValue: '',
      filterOptions: subscriptionStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    },
    {
      id: 'student',
      label: 'Tanuló id',
      filterInitialValue: studentId || '',
      filterValue: '',
      filterType: 'hidden',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useSubscriptionFilters;
