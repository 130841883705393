import useLanguagesApi from 'components/Languages/useLanguagesApi';
import TablePage from '../../components/Common/TablePage';
import useLanguagesFields from 'components/Languages/useLanguagesFields';
import useLanguagesFilters from 'components/Languages/useLanguagesFilters';

const LanguagesPage = () => {
  const { getLanguages, createLanguage, updateLanguage } = useLanguagesApi();
  const fields = useLanguagesFields();
  const filtersConfig = useLanguagesFilters();

  return (
    <TablePage
      fields={fields}
      onGetRows={getLanguages}
      onCreateRow={createLanguage}
      onUpdateRow={updateLanguage}
      filterConfig={filtersConfig}
    />
  );
};

export default LanguagesPage;
