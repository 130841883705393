import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { localIsoStringToUtcTimestamp, utcTimestampToLocalIsoString } from 'utils/date';
import { TextField } from '@mui/material';

const DateInput = ({ name, defaultValue, loading, disabled, checkDisabled, helperText, ...dateFieldProps }) => {
  const { control } = useFormContext();

  const isDisabled = checkDisabled ? checkDisabled(defaultValue) : disabled;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        const handleChange = (newValue) => {
          if (newValue !== '') {
            onChange(localIsoStringToUtcTimestamp(newValue));
          }
        };
        return (
          <DesktopDatePicker
            renderInput={(params) => <TextField fullWidth {...params} error={!!error} helperText={error?.message ?? helperText} />}
            variant='outlined'
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            value={value ? utcTimestampToLocalIsoString(value) : null}
            inputRef={ref}
            disabled={isDisabled || loading}
            format='yyyy/MM/dd'
            InputLabelProps={{ shrink: true }}
            {...dateFieldProps}
          />
        );
      }}
    />
  );
};

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DateInput;
