import * as yup from 'yup';
import { DATE_FORMATS, formatDate } from 'utils/date';
import useOptions from 'components/Options/OptionsContext/useOptions';
import StatusChip from '../Common/StatusChip';
import ConnectedItemsList from './ConnectedItemsList';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import useTranslation from 'hooks/useTranslation';

const useCouponFields = () => {
  const { courses, students } = useOptions();
  const { t } = useTranslation();

  return [
    {
      name: 'name',
      type: 'text',
      showInTable: true,
      cellRenderer: (row) => row.name,
      fieldProps: {
        label: 'Elnevezés'
      }
    },
    {
      name: 'discount',
      type: 'text',
      showInTable: true,
      cellRenderer: (row) => row.discount,
      fieldProps: {
        label: 'Kedvezmény',
        helperText: t('helpertext.discount')
      },
      rule: yup
        .string()
        .test('number-or-percentage', 'A kedvezmény összegszerű vagy százalékos megadása szükséges', (value) => {
          const pattern = /^(?:100\s*%|\d{1,2}\s*%|\d+)$/gm;
          return pattern.test(value);
        })
    },
    {
      name: 'expirationDate',
      type: 'date',
      showInTable: true,
      cellRenderer: (row) => (row.expirationDate ? formatDate(row.expirationDate, DATE_FORMATS.shortDate) : '-'),
      fieldProps: {
        label: 'Lejárat'
      }
    },
    {
      name: 'usageLimit',
      type: 'number',
      showInTable: true,
      cellRenderer: (row) => row.usageLimit ?? 'Korlátlan',
      fieldProps: {
        label: 'Felhasználási limit / fő',
        step: '1',
        helperText: t('helpertext.coupon-usageLimit')
      },
      rule: yup
        .string()
        .test('number-or-null', 'A limit mező egy pozitív szám, vagy üres mező kell legyen', (value) => {
          if (!value) return true;
          const valueAsInt = parseInt(value);
          return valueAsInt > 0;
        })
    },
    {
      name: 'courses',
      type: 'multiselect',
      showInTable: true,
      cellRenderer: (row) =>
        row.courses.length > 0 ? (
          <ConnectedItemsList icon={<BookOutlinedIcon />} values={row.courses} />
        ) : (
          'Minden kurzus'
        ),
      fieldProps: {
        label: 'Kapcsolódó tanfolyamok',
        options: courses,
        multiselect: true,
        helperText: t('helpertext.coupon-courses')
      },
      rule: yup.array()
    },
    {
      name: 'students',
      type: 'multiselect',
      showInTable: true,
      cellRenderer: (row) =>
        row.students.length > 0 ? (
          <ConnectedItemsList icon={<PeopleOutlineOutlinedIcon />} values={row.students} />
        ) : (
          'Minden tanuló'
        ),
      fieldProps: {
        label: 'Kapcsolódó tanulók',
        options: students,
        multiselect: true,
        helperText: t('helpertext.coupon-students')
      },
      rule: yup.array()
    },
    {
      name: 'status',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='coupon' />,
      fieldProps: {
        label: 'Státusz'
      }
    }
  ];
};

export default useCouponFields;
