import React, { useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { FilterForDesktop } from './filter-for-desktop';
import { FilterForMobile } from './filter-for-mobile';

export const FilterType = {
  Checkbox: 'checkbox',
  Select: 'select',
  MultiSelect: 'multiselect',
  Search: 'search',
  Date: 'date'
};

export const getInitFilterValue = (type) => {
  const initFilterValue = {
    [FilterType.Checkbox]: false,
    [FilterType.Select]: '',
    [FilterType.MultiSelect]: [],
    [FilterType.Search]: '',
    [FilterType.Date]: ''
  };
  return initFilterValue[type];
};

const TableFilter = ({ initialFilters, onFilterChange, sx }) => {
  const [selectedFilters, setSelectedFilters] = useState(
    initialFilters.map((filter) => ({
      ...filter,
      inactiveValue: filter.filterInitialValue || filter.filterValue,
      filterValue: filter.filterInitialValue || filter.filterValue
    }))
  );

  const filtersToShow = selectedFilters.filter(f => f.isVisible == null ? true : f.isVisible(selectedFilters));

  const setFilters = (newFilters) => {
    setSelectedFilters(newFilters);

    if (typeof onFilterChange === 'function') {
      onFilterChange(newFilters);
    }
  };

  const handleFilterChange = (id, filterValue) => {
    const newState = selectedFilters.map((filter) => {
      if (filter.id !== id) {
        return filter;
      }

      return {
        ...filter,
        filterValue
      };
    });

    setFilters(newState);
  };

  const handleFiltersChange = (id, value) => {
    const newState = selectedFilters.map((filter) => {
      if (filter.id !== id) {
        return filter;
      }

      const filterValue = filter.filterValue;
      const newFilterValue = filterValue.includes(value)
        ? filterValue.filter((val) => val !== value)
        : [...filterValue, value];

      return {
        ...filter,
        filterValue: newFilterValue
      };
    });

    setFilters(newState);
  };

  const handleFilterClear = () => {
    setFilters(
      initialFilters.map((filter) => ({
        ...filter,
        inactiveValue: filter.filterInitialValue || filter.filterValue,
        filterValue: filter.filterInitialValue || filter.filterValue
      }))
    );
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return isMobile ? (
    <FilterForMobile
      sx={sx}
      selectedFilters={filtersToShow}
      handleFiltersChange={handleFiltersChange}
      handleFilterChange={handleFilterChange}
      handleFilterClear={handleFilterClear}
    />
  ) : (
    <FilterForDesktop
      sx={sx}
      selectedFilters={filtersToShow}
      handleFiltersChange={handleFiltersChange}
      handleFilterChange={handleFilterChange}
      handleFilterClear={handleFilterClear}
    />
  );
};

export default TableFilter;
