import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewerContext from './ViewerContext';
import PageLoader from 'components/PageLoader/PageLoader';
import useApi from 'api/useApi';
import { useError } from 'hooks/useError';
import useOptions from 'components/Options/OptionsContext/useOptions';

const VIEWER_ROLES = {
  STUDENT: 0,
  TEACHER: 1,
  OPERATOR: 5,
  ADMIN: 9
};

const ViewerContextProvider = ({ children }) => {
  const [viewer, setViewer] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateToken, api } = useApi();
  const { enqueueErrorSnackbar } = useError();
  const { setOptions } = useOptions();
  const [showHelp, setShowHelp] = useState(window.localStorage.getItem('showHelp') === 'false' ? false : true);

  useEffect(() => {
    window.localStorage.setItem('showHelp', showHelp);
  }, [showHelp]);

  useEffect(() => {
    const checkViewer = async () => {
      const token = localStorage.getItem('viewer');
      if (token) {
        api({
          url: '/auth',
          method: 'get',
          headers: {
            'X-Authorization': token
          }
        })
          .then(({ data: { user, options } }) => {
            setViewer(user);
            setOptions(options);
            setLoading(false);
          })
          .catch(function () {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };

    checkViewer();
  }, []);

  const logout = () => {
    localStorage.removeItem('viewer');
    setViewer(null);
    navigate('/login');
  };

  const login = async (fields) => {
    const { from } = location.state || { from: { pathname: '/' } };
    await api({
      url: '/auth/login',
      data: fields,
      method: 'post'
    })
      .then(({ data: { user, options } }) => {
        if (user.token) {
          setViewer(user);
          setOptions(options);
          updateToken(user.token);

          localStorage.setItem('viewer', user.token);

          navigate(from);
          return true;
        }
      })
      .catch((error) => {
        enqueueErrorSnackbar(error);
        return false;
      });
  };

  const signup = async (fields) => {
    try {
      await api({
        url: '/auth/signup',
        data: fields,
        method: 'post'
      })
      navigate('/user-confirm');
    } catch (e) {
      enqueueErrorSnackbar(e);
      throw e;
    }
  };

  const confirmUser = async (fields) => {
    let result = false;
    await api({
      url: '/auth/activate',
      data: fields,
      method: 'post'
    })
      .then(() => {
        result = true;
      })
      .catch(() => {
        result = false;
      });
    return result;
  };

  const lostPassword = async (fields) => {
    let result = false;
    await api({
      url: '/auth/lostpassword',
      data: fields,
      method: 'post'
    })
      .then(() => {
        result = true;
      })
      .catch(() => {
        result = false;
      });
    return result;
  };

  const updateViewer = (fields) => {
    setViewer({ ...viewer, ...fields });
  };

  const viewerHasRole = (role) => parseInt(viewer?.role) >= role;

  const viewerRoleIs = (role) => parseInt(viewer?.role) === role;

  return (
    <>
      {loading ? (
        <PageLoader fullPage text='Loading' />
      ) : (
        <ViewerContext.Provider
          value={{
            viewer,
            login,
            logout,
            signup,
            confirmUser,
            lostPassword,
            viewerRoles: VIEWER_ROLES,
            viewerHasRole,
            viewerRoleIs,
            updateViewer,
            showHelp,
            toggleHelp: () => setShowHelp(!showHelp)
          }}
        >
          {children}
        </ViewerContext.Provider>
      )}
    </>
  );
};

export default ViewerContextProvider;
