import { useSearchParams } from 'react-router-dom';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import useOptions from '../Options/OptionsContext/useOptions';

const useTeacherFilters = () => {
  const { userStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: userStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    }
  ];
};

export default useTeacherFilters;
