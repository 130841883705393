import TablePage from 'components/Common/TablePage';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import useNotesApi from './useNotesApi';
import useNoteFields from './useNoteFields';
import useNoteFilters from './useNoteFilters';
import { useState } from 'react';

const StudentNotes = ({studentId, refetchTrigger, setRefetchTrigger}) => {
  const [showTodoFilters, setShowTodoFilters] = useState(false);
  const { getNotes, updateNote, deleteNote } = useNotesApi();
  const fields = useNoteFields(setRefetchTrigger);
  const { viewerHasRole, viewerRoles } = useViewer();
  const filterConfig = useNoteFilters(studentId, showTodoFilters);

  const onFilterChange = (filters) => {
    if (filters.noteType === 'todo') {
      setShowTodoFilters(true)
    } else {
      setShowTodoFilters(false)
    } 
  }

  return (
    <TablePage
      fields={fields}
      onGetRows={getNotes}
      onUpdateRow={viewerHasRole(viewerRoles.OPERATOR) ? updateNote : null}
      onDeleteRow={viewerHasRole(viewerRoles.OPERATOR) ? deleteNote : null}
      refetchTrigger={refetchTrigger}
      filterConfig={filterConfig}
      onFilterChange={onFilterChange}
    />
  );
};

export default StudentNotes;
