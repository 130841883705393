import { Box, FormGroup, IconButton, Paper, Stack, Typography } from '@mui/material';

import CheckboxFilter from './CheckboxFilter';
import InputFilter from './InputFilter';
import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';
import useTranslation from 'hooks/useTranslation';
import { CloseRounded } from '@mui/icons-material';
import { FilterType } from './TableFilter';

export const FilterForDesktop = ({
  sx,
  selectedFilters,
  handleFiltersChange,
  handleFilterChange,
  handleFilterClear
}) => {
  const { t } = useTranslation();
  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: { xs: 1, md: 3 },
        py: { xs: 0, md: 0 },
        mx: { xs: -1, md: -3 },
        mb: 1,
        border: 0,
        background: 'transparent',
        overflowX: 'auto',
        ...sx
      }}
      elevation={0}
      square
      variant='outlined'
    >
      <Box sx={{ flexGrow: 1 }}>
        <FormGroup>
          <Stack direction='row' alignItems='center' spacing={1} sx={{ alignItems: 'center' }}>
            <Typography sx={{ mr: 2, color: 'text.secondary' }}>{t('common.filter')}</Typography>
            {selectedFilters.map(({ filterValue, filterType, filterOptions, label, id, icon }) => {
              if (filterType === FilterType.MultiSelect && filterOptions && Array.isArray(filterValue)) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterOptions={filterOptions}
                    filterValue={filterValue}
                    handleMultiSelectChange={handleFiltersChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Select && filterOptions) {
                return (
                  <SelectFilter
                    key={id}
                    id={id}
                    label={label}
                    filterValue={filterValue}
                    filterOptions={filterOptions}
                    handleMultiSelectChange={handleFilterChange}
                    icon={icon}
                  />
                );
              }

              if (filterType === FilterType.Checkbox) {
                return (
                  <CheckboxFilter
                    id={id}
                    key={id}
                    label={label}
                    checked={filterValue}
                    handleCheckboxChange={handleFilterChange}
                  />
                );
              }

              if (filterType === FilterType.Date) {
                return (
                  <DateFilter
                    id={id}
                    key={id}
                    label={label}
                    filterValue={filterValue}
                    handleFilterChange={handleFilterChange}
                  />
                );
              }

              return null;
            })}
            <IconButton onClick={handleFilterClear}>
              <CloseRounded sx={{ color: 'text.secondary' }} />
            </IconButton>
          </Stack>
        </FormGroup>
      </Box>
      <Box>
        {selectedFilters
          .filter(({ filterType }) => filterType === FilterType.Search)
          .map(({ filterValue, label, id }) => (
            <InputFilter
              key={id}
              id={id}
              placeholder={label}
              filterValue={filterValue}
              handleFilterChange={handleFilterChange}
            />
          ))}
      </Box>
    </Paper>
  );
};
