import { Button, Dialog } from 'ui';
import useCourseApi from '../../components/Course/useCourseApi';
import useCourseFilters from '../../components/Course/useCourseFilters';
import CourseList from '../../components/Course/CourseList';
import useViewer from '../../components/Viewer/ViewerContext/useViewer';
import CourseForm from '../../components/Course/CourseForm';
import { useEffect, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';
import { useSearchParams } from 'react-router-dom';
import TableFilter from '../../ui/TableFilter';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Toolbar from 'components/Layout/Toolbar';

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  let [searchParams] = useSearchParams();
  const { viewer, viewerRoles, viewerHasRole } = useViewer();
  const [filters, setFilters] = useState({ language: searchParams.get('language') || '' }, viewer.role);
  const { t } = useTranslation();
  const { getCourses } = useCourseApi();
  const courseFilter = useCourseFilters();

  const fetchCourses = async () => {
    const response = await getCourses(filters);
    setCourses(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchCourses();
  }, [filters]);

  const handleFilterChange = (f) => {
    setFilters(f.reduce((acc, filter) => ({ ...acc, [filter.id]: filter.filterValue }), {}));
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleSaveCourse = () => {
    fetchCourses();
    handleCloseDialog();
  };

  return (
    <>
      {viewerHasRole(viewerRoles.ADMIN) && (
        <Toolbar>
          <Button onClick={() => setDialogOpen(true)} startIcon={<AddRoundedIcon />} variant='text' size='small'>
            {t('courses.new-course')}
          </Button>
        </Toolbar>
      )}
      {loading && <>loading courses...</>}
      {!loading && courses && (
        <>
          <TableFilter initialFilters={courseFilter} onFilterChange={handleFilterChange} />
          <CourseList courses={courses} loading={loading} />
        </>
      )}
      <Dialog title='Új tanfolyam' isOpen={isDialogOpen} onClose={handleCloseDialog} hideActions>
        <CourseForm onCancel={handleCloseDialog} onSave={handleSaveCourse} />
      </Dialog>
    </>
  );
};

export default CoursesPage;
