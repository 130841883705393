import StatusChip from '../Common/StatusChip';
import useOptions from '../Options/OptionsContext/useOptions';

const useStudentFields = () => {
  const { paymentProfiles } = useOptions();
  return [
    {
      name: 'name',
      cellRenderer: (row) => `${row.lastName || ''} ${row.firstName || ''}`,
      showInTable: true,
      fieldProps: {
        label: 'Név'
      }
    },
    {
      name: 'email',
      showInTable: true,
      fieldProps: {
        label: 'Email'
      }
    },
    {
      name: 'phone',
      showInTable: true,
      fieldProps: {
        label: 'Telefon'
      }
    },
    {
      name: 'paymentProfile',
      showInTable: true,
      cellRenderer: (row) => paymentProfiles.find((item) => item.key === row.paymentProfile)?.value || '',
      fieldProps: {
        label: 'Számlázási információ'
      }
    },
    {
      name: 'status',
      showInTable: true,
      cellRenderer: (row) => <StatusChip status={row.status} entity='user' />,
      fieldProps: {
        label: 'Státusz'
      }
    }
  ];
};

export default useStudentFields;
