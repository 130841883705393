import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TextInput = ({
  name,
  defaultValue,
  loading,
  disabled,
  checkDisabled,
  helperText,
  readOnly,
  ...textFieldProps
}) => {
  const { control } = useFormContext();
  const isDisabled = checkDisabled ? checkDisabled(defaultValue) : disabled;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        return (
          <TextField
            fullWidth
            variant='outlined'
            name={name}
            error={!!error}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            helperText={error?.message ?? helperText}
            readOnly={readOnly}
            disabled={isDisabled || loading}
            InputLabelProps={{ shrink: true }}
            {...textFieldProps}
          />
        );
      }}
    />
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default TextInput;
