import { useState } from 'react';
import { Hidden } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useViewer from 'components/Viewer/ViewerContext/useViewer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog, Form } from 'ui';
import useCreateNoteFields from './useCreateNoteFields';
import useNotesApi from './useNotesApi';

const NewNoteButton = ({ studentId, setRefetchTrigger }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const { t } = useTranslation();
  const { viewerHasRole, viewerRoles } = useViewer();
  const { createNote } = useNotesApi();
  const fields = useCreateNoteFields();

  const onSubmit = async (data) => {
    setFormLoading(true);
    await createNote({
      ...data,
      student: studentId
    });
    setRefetchTrigger((prev) => prev + 1);
    setDialogOpen(false);
    setFormLoading(false);
  };

  if (!viewerHasRole(viewerRoles.OPERATOR)) {
    return null;
  }

  return (
    <>
      <Button startIcon={<AddRoundedIcon />} variant='contained' onClick={() => setDialogOpen(true)}>
        <Hidden smDown>{t('students.notes-new')}</Hidden>
      </Button>

      <Dialog title={t('students.notes-new')} isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} hideActions>
        <Form 
          fields={fields}
          onSubmit={onSubmit}
          onAltAction={() => setDialogOpen(false)}
          submitLabel={t('students.create-new-note')}
          loading={isFormLoading}
        />
      </Dialog>
    </>
  );
};

export default NewNoteButton;
