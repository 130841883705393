import React, { useState } from 'react';
import { Dialog } from '../../ui';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const EntryBookConfirmationDialog = ({ isOpen, onConfirm, onClose, entry }) => {
  const isMixedLocationEntry = entry.locationType === 'mixed' && entry.availableLocationType === 'mixed';
  const onlyOnlineAvailable = entry.locationType === 'mixed' && entry.availableLocationType === 'online';
  const onlyPersonalAvailable = entry.locationType === 'mixed' && entry.availableLocationType === 'personal';
  const [location, setLocation] = useState(null);
  const handleConfirm = () => {
    onConfirm(isMixedLocationEntry ? location : entry.availableLocationType);
    setLocation(null);
  };

  const handleClose = () => {
    onClose();
    setLocation(null);
  };

  return (
    <Dialog
      title='Foglalás megerősítése'
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={handleClose}
      confirmDisabled={isMixedLocationEntry && !location}
    >
      <Typography gutterBottom>
        Ha a foglalást megerősíted, akkor ezzel az időtartammal megterheljük a jelenleg aktív bérletedet. Az időpontot
        legkésőbb a foglalást megelőző napon 20:00-ig módosíthatod vagy törölheted.
      </Typography>
      {isMixedLocationEntry && (
        <Box>
          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
            Ezen az időponton online és személyesen is részt vehetsz. Kérünk, hogy jelöld meg a résztvételi módódat.
          </Typography>

          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            onChange={(e) => setLocation(e.target.value)}
          >
            <FormControlLabel value='personal' control={<Radio />} label='Személyes' />
            <FormControlLabel value='online' control={<Radio />} label='Online' />
          </RadioGroup>
        </Box>
      )}
      {onlyOnlineAvailable && (
        <Box>
          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
            Ebben az idősávban már csak online részvételre van lehetőséged.
          </Typography>
        </Box>
      )}
      {onlyPersonalAvailable && (
        <Box>
          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
            Ebben az idősávban már csak személyes részvételre van lehetőséged.
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default EntryBookConfirmationDialog;
