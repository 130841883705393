import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import useOptions from '../Options/OptionsContext/useOptions';
import { useSearchParams } from 'react-router-dom';
import { getDayInUnixTimestamp } from 'utils/date';
import moment from 'moment';

const useEntryFilters = () => {
  const { entryStatuses } = useOptions();
  const [searchParams] = useSearchParams();
  return [
    {
      id: 'status',
      label: 'Státusz',
      filterInitialValue: searchParams.get('status') || '',
      filterValue: '',
      filterOptions: entryStatuses,
      filterType: 'select',
      icon: CheckCircleOutlineRoundedIcon
    },
    {
      id: 'startDate',
      label: 'Dátum',
      filterInitialValue: searchParams.get('startDate') || getDayInUnixTimestamp(new Date()).toString(),
      filterValue: '',
      filterType: 'date',
      icon: TodayRoundedIcon
    },
    {
      id: 'endDate',
      label: 'Dátum',
      filterInitialValue: searchParams.get('endDate') || getDayInUnixTimestamp(moment().add(6, 'months').toDate()).toString(),
      filterValue: '',
      filterType: 'date',
      icon: TodayRoundedIcon
    }
  ];
};

export default useEntryFilters;
