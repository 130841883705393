import { Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import useTranslation from 'hooks/useTranslation';

export const TableForDesktop = ({
  fields,
  rows,
  idField,
  onUpdateRow,
  onDeleteRow,
  onRowClick,
  handleRowClick,
  rowsPerPageValues,
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const { t } = useTranslation();

  return (
    <Paper variant='outlined' sx={{ overflowX: 'auto' }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {fields
              .filter((field) => field.showInTable)
              .map((field) => (
                <TableCell key={field.name}>{field.fieldProps.label}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row[idField]}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: (onUpdateRow || onDeleteRow || onRowClick) && !parseInt(row.locked) ? 'pointer' : 'default'
              }}
              hover
              onClick={() => handleRowClick(row)}
            >
              {fields
                .filter((field) => field.showInTable)
                .map((field) => (
                  <TableCell key={`${row[idField]}-${field.name}`}>
                    {field.cellRenderer ? field.cellRenderer(row) : row[field.name]}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageValues}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ borderTopColor: 'divider', borderTopStyle: 'solid', borderTopWidth: 1, width: '100%' }}
        labelRowsPerPage={t('table.rows-per-page')}
      />
    </Paper>
  );
};
